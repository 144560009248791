import { FC } from 'react'

export type SnowFlakeProps = {
	height?: number | string,
	width?: number | string,
}

const SnowFlake: FC<SnowFlakeProps> = ({height = '100%', width = '100&'}) => {
	const isHNum = typeof height === 'number' ? 'px' : ''
	const isWNum = typeof width === 'number' ? 'px' : ''
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={`${height}${isHNum}`} width={`${width}${isWNum}`}>
			<path d="M240 16c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 73.4L171.3 52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L208 134.6l0 93.7-81.1-46.8-21.7-81c-2.3-8.5-11.1-13.6-19.6-11.3S72 100.2 74.3 108.7l13.4 50.1L24.2 122.1c-7.7-4.4-17.4-1.8-21.9 5.9s-1.8 17.4 5.9 21.9l63.5 36.7L21.6 200C13 202.3 8 211 10.3 219.6s11.1 13.6 19.6 11.3l81-21.7L192 256l-81.1 46.8-81-21.7c-8.5-2.3-17.3 2.8-19.6 11.3S13 309.7 21.6 312l50.1 13.4L8.2 362.1C.5 366.6-2.1 376.3 2.3 384s14.2 10.3 21.9 5.9l63.5-36.7L74.3 403.3c-2.3 8.5 2.8 17.3 11.3 19.6s17.3-2.8 19.6-11.3l21.7-81L208 283.7l0 93.7-59.3 59.3c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L208 422.6l0 73.4c0 8.8 7.2 16 16 16s16-7.2 16-16l0-73.4 36.7 36.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L240 377.4l0-93.7 81.1 46.8 21.7 81c2.3 8.5 11.1 13.6 19.6 11.3s13.6-11.1 11.3-19.6l-13.4-50.1 63.5 36.7c7.7 4.4 17.4 1.8 21.9-5.9s1.8-17.4-5.9-21.9l-63.5-36.7L426.4 312c8.5-2.3 13.6-11.1 11.3-19.6s-11.1-13.6-19.6-11.3l-81 21.7L256 256l81.1-46.8 81 21.7c8.5 2.3 17.3-2.8 19.6-11.3s-2.8-17.3-11.3-19.6l-50.1-13.4 63.5-36.7c7.7-4.4 10.3-14.2 5.9-21.9s-14.2-10.3-21.9-5.9l-63.5 36.7 13.4-50.1c2.3-8.5-2.8-17.3-11.3-19.6s-17.3 2.8-19.6 11.3l-21.7 81L240 228.3l0-93.7 59.3-59.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L240 89.4 240 16z"/>
		</svg>
	)
}

export default SnowFlake
