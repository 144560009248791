import { FC, useState } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import RSDrawer from '../../components/layout/RSDrawer'
import RSButton from '../../components/buttons/RSButton'
import Treadmill from '../../components/icons/Treadmill'
import Road from '../../components/icons/Road'
import Trees from '../../components/icons/Trees'
import Mountain from '../../components/icons/Mountain'
import SnowFlake from '../../components/icons/SnowFlake'
import UmbrellaBeach from '../../components/icons/UmbrellaBeach'

export type SurfaceTypesProps = {
	open: boolean,
	setOpen: any,
	type?: string
	miles?: number
}

const SurfaceTypes: FC<SurfaceTypesProps> = ({open, setOpen, type = 'road', miles = 0}) => {
	const [activeSurface, setActiveSurface] = useState(type)

	const surfaceData = [{
		title: 'road',
		icon: <Road />,
		description: ``
	}, {
		title: 'trail',
		icon: <Trees />,
		description: `Be careful of footing. Slow your pace by 5%.`
	}, {
		title: 'hilly trail',
		icon: <Mountain />,
		description: `Your pacing will slow by 15% to accommodate for hilling terrain.`
	}, {
		title: 'slippery',
		icon: <SnowFlake />,
		description: `Your pacing will slow 20%. Please be safe as injury risk is higher on this surface.`
	}, {
		title: 'beach',
		icon: <UmbrellaBeach />,
		description: `Stay on packed sand near the water. Your plan slows by 5%.`
	}, {
		title: 'tread',
		icon: <Treadmill height={25} width={35}/>,
		description: `Your plan will convert to ${miles} per hour.`
	}]

	return <RSDrawer
		bottom
		fitContent
		popUpWidth='400px'
		open={open}
		onClose={() => setOpen(false)}
	>
		<Grid container xs={12} sx={{py: 1, px: 2}}>
			<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold', fontSize: '16px'}}>Change surface type</Grid>
			<Grid container xs={12} sx={{py: 1, px: 2}} justifyContent='center'>
				{surfaceData.map((sData: any, iS: number) => {
					const isActive = activeSurface === sData.title
					return <Button key={`surface-icons-${iS}`} onClick={()=> !isActive && setActiveSurface(sData.title)} sx={{borderRadius: '5px', backgroundColor: `#${isActive ? 'FCC26A':'e7e7e7'} !important`, height: '65px', width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', m: '5px', color: '#010101'}}>
						<Box sx={{height: '20px'}}>{sData.icon}</Box>
						<Box sx={{fontSize: '12px !important', fontFamily: 'Poppins', textTransform: 'capitalize'}}>
							{sData.title}
						</Box>
					</Button>
				})}
			</Grid>
			<Grid container xs={12} sx={{fontFamily: 'Poppins-SemiBold', fontSize: '14px'}}>Changes</Grid>
			<Grid container xs={12} sx={{fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.2em', minHeight: '35px'}}>
				{type === activeSurface ? 'No changes to plan.' : surfaceData.find((s: any) => s.title === activeSurface)?.description}
			</Grid>
			<Grid xs={12} sx={{ py: 1 }} container justifyContent="center" alignItems="flex-end">
				<RSButton disabled={type === activeSurface} sx={{ fontSize: '18px', height: '55px', m: '0px 16px 5px 16px', maxWidth: '200px', mt: 1 }}>Save</RSButton>
			</Grid>
		</Grid>
	</RSDrawer>
}

export default SurfaceTypes;
