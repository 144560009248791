/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Grid } from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { CircleSpinner, RingSpinner } from 'react-spinner-overlay'
import { AppleHealthActivity } from '../../api/v2/apple-health-kit/apple-health-kit.types'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import { CreateAppleHealtActivity, GetAppleHealtActivity } from '../../modules/appleHealthKitActions'
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore'
import useStore from '../../store/useStore'
import AppleWatchWorkoutLaps from './AppleWatchWorkoutLaps'

interface AppleWatchWorkoutProps {
    userInfo: any
    selectedWeek: any
    selectedDay: any,
    isLoading: boolean
    appleHealthActivity?: AppleHealthActivity
    workoutLaps: any[]
}

const AppleWatchWorkout: FC<AppleWatchWorkoutProps> = ({ userInfo, selectedDay, selectedWeek, isLoading, appleHealthActivity, workoutLaps }) => {

    const { getGarminPace, ConvertS2TimeObject, ConvertMetersToMiles } = useTrainingPlanUtils()

    const timeToString = (props: any) => {
        const { hr, min, sec } = props
        return (hr || min || sec) ? `${hr ? `${hr.toString().padStart(2, '0')}:` : ''}${min || (!min && hr) ? `${min.toString().padStart(2, '0')}:` : ''}${(sec || !sec && (min || hr)) ? `${Math.floor(sec).toString().padStart(2, '0')}` : ''}` : ''
    }

    const getWokoutIcon = (act: string) => {
        const activityName = act.toLowerCase()
        if (activityName.includes('running')) return 'fa-duotone fa-solid fa-person-running'
        else if (activityName.includes('track')) return 'fa-duotone fa-solid fa-person-running'
        else if (activityName.includes('walking')) return 'fa-duotone fa-solid fa-person-walking'
        else if (activityName.includes('cycling')) return 'fa-duotone fa-solid fa-person-biking'
        else if (activityName.includes('swimming')) return 'fa-duotone fa-solid fa-person-swimming'
        else if (activityName.includes('yoga')) return 'fa-duotone fa-solid fa-person'
        else if (activityName.includes('workout')) return 'fa-duotone fa-solid fa-dumbbell'
        else if (activityName.includes('training')) return 'fa-duotone fa-solid fa-dumbbell'
        else return 'fa-duotone fa-solid fa-dumbbell'
    }

    const renderAppleHealthActivity = (isFromDrawer?: boolean) => {
        if (isLoading) {
            return <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: "15vh" }} className='no-border p-2'>
                <RingSpinner size={30} color='#686769' />
                <p className='mt-3'>Loading data, please wait...</p>
            </div>
        }
        else
            if (appleHealthActivity) {
                const running = []
                const otherActivities = []
                const healthData = appleHealthActivity?.activity ? JSON.parse(appleHealthActivity?.activity) : []
                for (const act of healthData) {
                    const activityLaps = workoutLaps.filter((w: any) => w.activityId === act.id)
                    const isRunning = act.activityName.toLowerCase() === 'running'
                    const actInfoList = []
                    const actDistance = act.distanceInMeters ? (userInfo?.km == "N" ? ConvertMetersToMiles(act.distanceInMeters as number)?.toFixed(1) : Number(act.distanceInMeters as number / 1000)?.toFixed(1)) : ''
                    const actUnit = userInfo?.km == "N" ? 'Miles' : 'km'
                    const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'

                    if (act.runningSpeed) {
                        const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, act.runningSpeed)
                        const paceValueArr = paceDuration ? paceDuration.split(' ') : []
                        const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
                        const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')
                        actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
                                <Grid container justifyContent='center' alignItems='flex-end'>
                                    <Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{paceMin ? paceMin : ''}{paceSec ? paceSec : ''}</Box>
                                    <Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>/{paceUnit}</Box>
                                </Grid>
                                <Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Pace</Box>
                            </Grid>
                        </Grid>)
                    }

                    act.heartRate && actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
                        <Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
                            <Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{Number(act.heartRate).toFixed()}</Box>
                            <Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Average Heart Rate</Box>
                        </Grid>
                    </Grid>)

                    if (act.durationInSeconds) {
                        const actDuration = timeToString(ConvertS2TimeObject(act.durationInSeconds))
                        actDuration && actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
                                <Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{actDuration}</Box>
                                <Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Total Time</Box>
                            </Grid>
                        </Grid>)
                    }

                    act.calories && actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
                        <Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
                            <Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{Number(act.calories).toFixed()}</Box>
                            <Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Active Calories</Box>
                        </Grid>
                    </Grid>)

                    const finalList = []
                    let rowList = []
                    for (const actInfo in actInfoList) {
                        rowList.push(actInfoList[actInfo])
                        if (Number(actInfo) % 2 === 1 || Number(actInfo) === actInfoList.length - 1) {
                            finalList.push(rowList)
                            rowList = []
                        }
                    }

                    const element = <Grid container sx={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', p: '1.2rem 0.9rem 0rem 0.9rem', borderRadius: '10px', mb: '10px' }}>

                        {/* <Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.sourceName + ' - ' + act.activityName}</Grid> */}
                        {/* {actDistance && <Grid container xs={12}>
                            <Grid container justifyContent='center' alignItems='center' sx={{ textTransform: 'none', color: 'black !important', p: '15px 0px 5px 0px', my: '5px', mr: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important', width: '120px' }}>
                                <Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}>{actDistance}</Box>
                                <Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>{actUnit}</Box>
                            </Grid>
                        </Grid>} */}

                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            {/* @@@ Daily Activity Results Header */}
                            <i className={getWokoutIcon(act.activityName || '')} style={{ fontSize: '40px', marginRight: '0.4rem' }}></i>
                            <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column' }} justifyContent='flex-start'>
                                <Grid sx={{ fontFamily: 'Poppins-Bold', fontSize: '23px' }} >{isRunning ? 'Running' : act.activityName}</Grid>
                                <Box sx={{ backgroundColor: '#DDD', width: 'fit-content', px: '1rem', borderRadius: '10px', display: 'flex', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>
                                    <Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.sourceName}</Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} sx={{ flexDirection: 'row', mt: '1.5rem', pb: actDistance ? '1rem' : '0', borderBottom: '1px solid #AAA' }}>
                            {
                                actDistance &&
                                <Grid container sx={{ flexDirection: 'column' }} >
                                    <> <Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', mb: '0.8rem' }}>Distance</Grid>
                                        <Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '40px', lineHeight: '1.4rem' }} >
                                            {actDistance}
                                            <span style={{ fontFamily: 'Poppins', fontSize: '20px', color: '#999' }}>{actUnit}</span>
                                        </Grid> </>
                                </Grid>
                            }
                        </Grid>


                        {finalList.map((e: any, idx: number) => <Grid key={idx + 'info'} container xs={12} justifyContent='space-between' alignItems='center'>{e}</Grid>)}
                        {activityLaps && activityLaps.length ? (<AppleWatchWorkoutLaps key={act.id} data={activityLaps} userInfo={userInfo} />) : ''}
                    </Grid >

                    isRunning ? running.push(element) : otherActivities.push(element)

                }
                return <Box sx={{ width: '100%', fontFamily: 'Poppins', position: 'relative', mt: isFromDrawer ? '0px' : '10px' }}>
                    <Grid container xs={12} justifyContent='flex-start' alignItems="center">
                        {[...running, ...otherActivities]}
                    </Grid>
                </Box>
            }
            else
                return <div className='no-border mt-4 text-center p-2 mx-n4 mx-md-3'>
                    <p>No activity for this date. Rest up <br />or complete your training and check back.</p>
                </div>
    }

    return renderAppleHealthActivity()

}

export default AppleWatchWorkout