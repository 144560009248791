/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect } from 'react'

import { postReq } from '../modules/apiConsume'
import ChatCompletionPrompts, { AIUserDetails, Stage2Details } from '../modules/ChatCompletionPrompts'
import ChatCompletionPromptsSingle from '../modules/ChatCompletionPromptsSingle'
import useManualCalculations from '../pages/admin/useManualCalculations'
import useStore from '../store/useStore'
import { IAiTrainingPlanStore } from '../store/createAITrainingPlanStore'
import useAIPlanPrompts from '../modules/useAIPlanPrompts'
import moment from 'moment'
import CryptoJS from 'crypto-js';

export type RSPlanAIProps = {
    stage1?: AIUserDetails
    stage2?: Stage2Details
}

const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

const XT_GLOSSARY = ['cross', 'train', 'day', 'xt']
const useRSPlanAIv2 = ({ stage1, stage2 }: RSPlanAIProps) => {

    // values can be transfered to a config file, a store, or a json file instead
    const isDailyRunAdjustmentPromptIncluded = false
    const rerunAttempts = 2

    const [finalPlan, setFinalPlan] = useState<any>()
    const [isLoading, setIsLoading] = useState<any>(false)
    const [fetchingAIresults, setFetchingAIresults] = useState<any>(false)
    const [buildStepAI, setBuildStepAI] = useState<number>(1)
    const [buildError, setBuildError] = useState<boolean>(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [displayLogCounter, setDisplayLogCounter] = useState<number>(0)

    const { buildStage2InputPrompts, buildStage2DailyRunPrompt, buildStage3RunTypePrompt } = ChatCompletionPrompts()
    const { defaultSytemPrompt, defaultSytemPrompt2, inputPrompts } = ChatCompletionPromptsSingle()
    const { buildUserDetails, buildStage2InputPrompts: buildStage2InputPromptsForRunner } = useAIPlanPrompts()
    const { applyRecoveryWeeks, applyTaperWeeks, getWithHighestWeeklyMileage, getLongRuns, syncLongRunDay, reArrangeMileage, hasOneOrTwoValueRuns, getHighestLongRun,
        convertWeeklyMileageData, convertDailyMileageData, morphAddRunTypes, appendCrossTrainingData, adjustLowMileageDays, patchWarmupDay, cleanupRaceWeek, setLastWeekToRacePace } = useManualCalculations()

    const { setDataGenerated } = useStore((state: IAiTrainingPlanStore) => state)

    const [stepsDataGenerated, setStepsDataGenerated] = useState<any>([])
    

    const rerunResults = []
    const testMode = false
    const displayLogs = testMode && displayLogCounter > 5

    const stage1AICall = async (userDetailsPrompt: string) => {
        let finalJson: any = {}
        let callArr = [{ role: 'system', content: defaultSytemPrompt }, { role: 'user', content: userDetailsPrompt }]
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(callArr), String(CRYPTO_KEY)).toString();

        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs })
        finalJson = response?.data?.output
        for (const prompts of inputPrompts) {
            callArr = [...callArr, { role: 'assistant', content: JSON.stringify(finalJson) }, { role: 'user', content: prompts }]
            const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(callArr), String(CRYPTO_KEY)).toString();
            // console.log('prompts', callArr)
            const res = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs })
            finalJson = res?.data?.output
            // console.log('prompts', finalJson)
        }
        const stage2InputPrompts = buildStage2InputPrompts(stage2)
        finalJson?.plan ? stage2AICall({ weekly_plan: finalJson.plan }, stage2InputPrompts) : setIsLoading(false)
    }

    const stage2AICall = async (weeklyPlan: any, stage2InputPrompts: Array<string>) => {
        let finalJson: any = {}
        let callArr = [{ role: 'system', content: defaultSytemPrompt2 }, { role: 'user', content: `Current training plan\n${JSON.stringify(weeklyPlan)}\n\n ${stage2InputPrompts[0]}` }]
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(callArr), String(CRYPTO_KEY)).toString();
        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs })
        finalJson = response?.data?.output
        for (const prompts of stage2InputPrompts.slice(1)) {
            callArr = [...callArr, { role: 'assistant', content: JSON.stringify(finalJson) }, { role: 'user', content: prompts }]
            // console.log('prompts2', callArr)
            const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(callArr), String(CRYPTO_KEY)).toString();
            const res = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs })
            finalJson = res?.data?.output
            // console.log('prompts', finalJson)
        }
        setFinalPlan(finalJson?.plan || [])
        setIsLoading(false)
    }

    const stage3aAICall = async (userDetails: any) => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)
    }

    const buildTablePlanOverview = async (userDetails: any) => {

        setIsLoading(true)

        const prompts = []
        // uses chatCompletionPrompts functions
        prompts.push(buildUserDetails(userDetails["1"]))
        prompts.push(buildStage2DailyRunPrompt(userDetails["2"]))
        prompts.push(buildStage3RunTypePrompt())

        // console.log('>>> prompts: ', prompts)

        let latestResults = {}
        let AIstep = 0
        let message: { role: string; content: string }[] = []

        for (const prompt of prompts) {

            AIstep++
            // console.log('>>> AIstep: ', AIstep)
            if (AIstep === 1) message = [{
                "role": "system",
                "content": defaultSytemPrompt
            }, {
                "role": "user",
                "content": prompt
            }]
            else message = [...message, { role: 'assistant', content: JSON.stringify(latestResults) }, { role: 'user', content: prompt }]
            const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();

            const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o', gptTemp: '0' })
            if (response && response.status === "error") {
                setIsLoading(false)
                alert(response.error.error.error.message)
                return
            }
            const curResults = response?.data?.output || []

            // console.log('>>> AIstep results: ', curResults)

            latestResults = curResults

            if (AIstep === 3) {
                setIsLoading(false)
                // console.log('>>> Finished AI steps.')

                return latestResults
            }
        }
    }


    const buildTablePlanOverviewV2 = async (userDetails: any, newUpdate: boolean) => {

        // if(!updatedDetails) return
        // setIsLoading(true)
        setFetchingAIresults(true)

        const raceType = userDetails["1-0"]?.raceType || 'Half'
        const isKRuns = raceType.toUpperCase() === '5K' || raceType.toUpperCase() === '10K' || raceType.toUpperCase() === '8K'
        const race_day = moment(userDetails["1-0"]?.raceDate).format('dddd')
        const longRuns: any = []
        const weeklyMileage: any = []
        const taperRecoveryWeeks: any = []
        const defaultLongRunDay = 'sunday'
        let skipDailyRunAdjustments = false

        userDetails["0"].stepResults = []
        if (userDetails["0"].latestRevision) {
            if (userDetails["0"].latestRevision < newUpdate) userDetails["0"].latestRevision = newUpdate
            else return
        }
        else userDetails["0"].latestRevision = newUpdate
        const weeklyPlanDetails = { ...userDetails["1-0"], ...userDetails["1-1"], ...userDetails["1-2"], ...userDetails["2"] }

        const prompts = []
        prompts.push(buildUserDetails({ ...weeklyPlanDetails, race_day: race_day }))

        // console.log({ ...weeklyPlanDetails, race_day: race_day })

        let message: { role: string; content: string }[] = []
        let promptSequences = prompts.length + 1

        for (let AIstep = 1; AIstep < promptSequences; AIstep++) {

            let latestResults: any[] = []
            let modifiedResults = undefined
            const savedStepResults = []
            const prompt = prompts[prompts.length - 1].toString()

            // console.log('>>> Revision: ', newUpdate)
            displayLogs && console.log('>>> AIstep: ', AIstep)
            displayLogs && console.log('>>> prompt: ', prompt)

            message = [{
                "role": "system",
                "content": defaultSytemPrompt
            }, {
                "role": "user",
                "content": prompt
            }]

            const recievedAIbundle = await runPrompt(message)
            const usedTokens = recievedAIbundle.tokens
            let recievedAIresponse = recievedAIbundle.plan
            latestResults = [...recievedAIresponse]


            // apply weekly restrictions
            if (AIstep === 1) {

                // console.log('applying weekly restrictions')

                let weekliesReruns = 0
                const initialCount = 1
                // intialCount is 1 for the first attempt
                // adding 1 more for the final check and array modifications but not a rerun
                for (let i = initialCount; i <= (1 + initialCount + rerunAttempts); i++) {

                    i > 1 && displayLogs && console.log('>>> weeklies result rerun check: ', (i - 1))
                    weekliesReruns = i - 1 <= rerunAttempts ? i - 1 : rerunAttempts

                    recievedAIresponse = convertWeeklyMileageData(recievedAIresponse)
                    latestResults = convertWeeklyMileageData(latestResults)
                    // console.log('>>> weekly latestResults: ', latestResults)

                    const longestRunDistancePreferred = weeklyPlanDetails.maxRunPerWeek && weeklyPlanDetails.maxRunPerWeek || 22

                    modifiedResults = getLongRuns(applyRecoveryWeeks(applyTaperWeeks(getWithHighestWeeklyMileage(latestResults), raceType), raceType) || [], raceType, Number(longestRunDistancePreferred || 0), weeklyPlanDetails?.lastLongestRun as number, getHighestLongRun(latestResults), userDetails['1-0']?.unit.toLowerCase() === "miles" ? false : true) || []
                    latestResults = modifiedResults && modifiedResults.length > 0 ? modifiedResults : latestResults

                    displayLogs && console.log('>>> weeklies modified result: ', latestResults)

                    // const savedWeekliesReruns = ' [' + weekliesReruns + ']'
                    // setDataGenerated({ type: "step " + (AIstep + 1) + savedWeekliesReruns, data: { unmodified: recievedAIresponse, modified: latestResults } })

                    const validResults = checkIfValidPlanWeeklyAfterAdjustments(latestResults, weeklyPlanDetails)
                    validResults && displayLogs && console.log('>>> valid weekly results')
                    if (!validResults && (i - initialCount) <= rerunAttempts) {
                        const recievedResults = await runPrompt(message)
                        latestResults = recievedResults.plan
                    }
                    else break

                }

                userDetails["0"].weekliesReruns = weekliesReruns


                latestResults.forEach((e: any) => {
                    longRuns.push({ w: e.Week, lr: e.Long_run })
                    weeklyMileage.push({ w: e.Week, wm: e.Weekly_mileage })
                });

                const taperWeeks = latestResults.filter((x: any) => x.Taper)
                const recoveryWeeks = latestResults.filter((x: any) => x.Recovery)
                taperRecoveryWeeks.push([...taperWeeks, ...recoveryWeeks])

                setDataGenerated({ type: 'step 1', data: [{ longRuns, weeklyMileage, latestResults }] })
                setStepsDataGenerated([...stepsDataGenerated, { type: 'step 1', data: [{ longRuns, weeklyMileage, latestResults }] } ])


            }

            // daily run adjustments
            if (AIstep === 2) {

                // console.log('applying daily run adjustments')

                let dailiesReruns = 0
                const initialCount = 1
                // intialCount is 1 for the first attempt
                // adding 1 more for the final check and array modifications but not a rerun
                for (let i = initialCount; i <= (1 + initialCount + rerunAttempts); i++) {

                    // displayLogs && console.log('>>> daily run attempt: ', i)
                    i > 1 && displayLogs && console.log('>>> dailies rerun check: ', (i - 1))
                    dailiesReruns = i - 1 <= rerunAttempts ? i - 1 : rerunAttempts
                    // just transforming array
                    recievedAIresponse = convertDailyMileageData(recievedAIresponse)
                    displayLogs && console.log('>>> 2.1 daily ai results: ', recievedAIresponse)
                    displayLogs && console.log('>>> 2.2 converted daily mileage: ', convertDailyMileageData(recievedAIresponse))
                    displayLogs && console.log('>>> 2.3 re-arranged mileage: ', reArrangeMileage(convertDailyMileageData(recievedAIresponse), raceType))

                    modifiedResults = { plan: reArrangeMileage(convertDailyMileageData(latestResults), raceType) }
                    // if (raceType.toLowerCase() === 'half' || raceType.toLowerCase() === 'full')
                    modifiedResults = { plan: adjustLowMileageDays(modifiedResults.plan) }

                    if (isKRuns) {
                        modifiedResults = { plan: syncLongRunDay(modifiedResults?.plan, weeklyPlanDetails?.preferredLongRun || defaultLongRunDay, longRuns, taperRecoveryWeeks) }


                        const dayBefore = race_day.toLowerCase()
                        modifiedResults = { plan: patchWarmupDay(modifiedResults.plan, dayBefore) }

                        displayLogs && console.log('>>> 2.5 synced long run days: ', modifiedResults.plan)
                        // modifiedResults = { plan: cleanupRaceWeek(modifiedResults.plan, race_day) }
                    }
                    // console.log('>>> daily latestResults: ', modifiedResults)

                    skipDailyRunAdjustments = isDailyRunAdjustmentPromptIncluded && (hasOneOrTwoValueRuns(modifiedResults?.plan) ? false : true)

                    displayLogs && console.log('>>> 2.4 adjusted low mileage: ', reArrangeMileage(convertDailyMileageData(recievedAIresponse), raceType))

                    if (skipDailyRunAdjustments) {
                        modifiedResults = { plan: syncLongRunDay(modifiedResults?.plan, weeklyPlanDetails?.preferredLongRun || defaultLongRunDay, longRuns, taperRecoveryWeeks) }
                    } else {
                        if (!isKRuns) {
                            // applying it nonetheless (just removed prompt but all applied functions on removed step/prompt are still applied)
                            modifiedResults = { plan: syncLongRunDay(modifiedResults?.plan, weeklyPlanDetails?.preferredLongRun || defaultLongRunDay, longRuns, taperRecoveryWeeks) }
                            displayLogs && console.log('>>> 2.5 synced long run days: ', modifiedResults.plan)
                        }
                    }
                    // else if (!skipDailyRunAdjustments) console.log('Not Skipping Step')

                    // if (!modifiedResults || modifiedResults.plan.length < 1) console.log('unmodified results')
                    latestResults = modifiedResults && modifiedResults?.plan?.length > 0 ? modifiedResults.plan : latestResults

                    // const savedDailiesReruns = ' [' + dailiesReruns + ']'
                    // setDataGenerated({ type: "step " + (AIstep + 1) + savedDailiesReruns, data: { unmodified: recievedAIresponse, modified: latestResults } })

                    const validResults = checkIfValidPlanDailyAfterAdjustments(latestResults, weeklyPlanDetails)
                    validResults && displayLogs && console.log('>>> valid results')
                    if (!validResults && (i - initialCount) <= rerunAttempts) {
                        const recievedResults = await runPrompt(message)
                        latestResults = recievedResults.plan
                    }
                    else break
                }

                userDetails["0"].dailiesReruns = dailiesReruns
            }
            // else if (AIstep === 4 && !skipDailyRunAdjustments) {
            // 	modifiedResults = { plan: syncLongRunDay(latestResults, longRunDay, initLongRuns, taperRecoveryWeeks) }
            // }



            // assigned run types
            if ((!isDailyRunAdjustmentPromptIncluded && AIstep >= 3) || AIstep >= 4) {
                // if (isKRuns) {
                latestResults = setLastWeekToRacePace(latestResults)
                // }
                latestResults = morphAddRunTypes(userDetails["0"].stepResults.find((e: any) => e.step === AIstep)?.data, latestResults)

                displayLogs && console.log('>>> AIstep 3|4 results: ', latestResults)

                // results to be used by next plan addons like crossfit-training
                // userDetails["0"].stepResults.push({ step: AIstep + 1, data: latestResults, revision: newUpdate })
                userDetails["0"].stepResults.push({ step: AIstep + 1, data: { unmodified: recievedAIresponse, modified: latestResults, tokens: usedTokens }, revision: newUpdate })

                // setDataGenerated({ type: "step " + (AIstep + 1), data: { unmodified: recievedAIresponse, modified: latestResults } })

                setIsLoading(false)

                // console.log('>>> Finished AI Base daily runs.')

                setBuildStepAI(AIstep)
                return latestResults

            } else if (AIstep >= 1) {

                if (isDailyRunAdjustmentPromptIncluded && skipDailyRunAdjustments && AIstep === 2) {
                    // skipping step, adding 1 to AIstep
                    AIstep++
                    promptSequences++
                }

                // giving results needed for the next prompt/step, so we add 1
                // userDetails["0"].stepResults.push({ step: AIstep + 1, data: latestResults, revision: newUpdate })
                userDetails["0"].stepResults.push({ step: AIstep + 1, data: { unmodified: recievedAIresponse, modified: latestResults, tokens: usedTokens }, revision: newUpdate })
                userDetails["0"].currentStep = AIstep + 1

                const mergedUserDetails = { ...userDetails["0"], ...weeklyPlanDetails, ...userDetails["3"] }
                // console.log('>>> mergedUserDetails: ', mergedUserDetails)

                if (userDetails["0"].latestRevision > newUpdate) return

                const allDailyRunPrompts = buildStage2InputPromptsForRunner({ ...mergedUserDetails, race_day: race_day })
                // index starts at 0, so we subtract 1
                prompts.push(allDailyRunPrompts[AIstep - 1])
                promptSequences++

                // console.log('applying next step')

            }

            setBuildStepAI(AIstep)
        }
    }



    // checks if plan follows the rules & prompts
    const checkIfValidPlanDaily = (AIresults: any, userInputDetails: any) => {

        displayLogs && console.log('>>> checking valid plan: ', AIresults)

        const suggestedDailyRunsBeforeRaceWeek = userInputDetails?.runDaysPerWeek * (userInputDetails?.planWeeks - 1)
        const leewayFormula = Math.round(suggestedDailyRunsBeforeRaceWeek * 0.05)
        const leewayDays = leewayFormula < 2 ? 2 : leewayFormula
        let totalAssignedRunDays = 0
        let week = 0
        AIresults.forEach((obj: any) => {
            week++
            // only count before race week
            if (week >= AIresults.length) return
            for (const key in obj) {
                const numKey = parseInt(key, 10)
                !isNaN(numKey) && obj[key] > 0 && totalAssignedRunDays++
            }
        })

        displayLogs && console.log('>>> suggested daily run before race week: ', suggestedDailyRunsBeforeRaceWeek)
        displayLogs && console.log('>>> AI plan total assigned run days: ', totalAssignedRunDays)

        return (suggestedDailyRunsBeforeRaceWeek + leewayDays) >= totalAssignedRunDays ? true : false
    }


    const checkIfValidPlanDailyAfterAdjustments = (AIresults: any, userInputDetails: any) => {

        displayLogs && console.log('>>> checking valid dailies plan: ', AIresults)

        const suggestedDailyRunsBeforeRaceWeek = userInputDetails?.runDaysPerWeek * (userInputDetails?.planWeeks - 1)
        const leewayFormula = Math.round(suggestedDailyRunsBeforeRaceWeek * 0.05)
        const leewayDays = leewayFormula < 2 ? 2 : leewayFormula
        let totalAssignedRunDays = 0
        const daysString = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

        AIresults.forEach((week: any, wI: number) => {
            if ((wI + 1) >= AIresults.length) return
            // only count before race week
            if (week >= AIresults.length) return
            for (const day in week) {
                if (daysString.includes(day) && week[day] > 0) {
                    totalAssignedRunDays++
                }
            }
        })

        displayLogs && console.log('>>> suggested daily run before race week: ', suggestedDailyRunsBeforeRaceWeek)
        displayLogs && console.log('>>> plan total assigned run days after adjustments: ', totalAssignedRunDays)

        return (suggestedDailyRunsBeforeRaceWeek + leewayDays) >= totalAssignedRunDays ? true : false
    }


    const checkIfValidPlanWeeklyAfterAdjustments = (AIresults: any, userInputDetails: any) => {

        const maxInitialWeekly = userInputDetails.currentWeeklyMileage || userInputDetails.averageRunPerWeek
        const maxPeakWeekly = userInputDetails.maxRunPerWeek && (userInputDetails.maxRunPerWeek + userInputDetails.maxRunPerWeek * 0.1)

        let validWeekly = true
        AIresults.forEach((week: any, wI: number) => {
            if (wI === 0 && week.Weekly_mileage > maxInitialWeekly) validWeekly = false
            if (week.Weekly_mileage > maxPeakWeekly) validWeekly = false
        })

        displayLogs && console.log('>>> max initial weekly: ', maxInitialWeekly)
        displayLogs && console.log('>>> max peak weekly ', maxPeakWeekly)
        displayLogs && !validWeekly && console.log('>>> invalid weekly plan')

        return validWeekly
    }

    // adjust func to handle other step results if needed, but now only XTs
    const handleXTresultsFormat = (results: any, planWeeks: any) => {

        const crossTraining: any[] = []

        results.forEach((e: any) => {

            for (const key in e) {
                let included = false

                for (const word of XT_GLOSSARY) {
                    if (!included && key.toLowerCase()?.includes(word.toLowerCase())) {
                        if (Array.isArray(e[key])) crossTraining.push(...e[key])
                        else crossTraining.push(e[key])
                        included = true
                    }
                }

                if (!included && e.length > 0 && (isNaN(Number(key)) || e.length <= 5)) {
                    e.forEach((week: any) => {
                        if (week.length === 7) Array.isArray(week) ? crossTraining.push(...week) : crossTraining.push(week)
                    })
                }
            }
        })

        displayLogs && crossTraining.length > 0 && console.log('>>> handled crossTraining result: ', crossTraining)
        if (crossTraining.length > 1 && (planWeeks && crossTraining.length === planWeeks)) return crossTraining
        else return results
    }


    const runPrompt = async (message: any) => {
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();

        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o', gptTemp: '0' })
        let responseInfo = { plan: [], tokens: [] }
        if (response && response.status === "error") {
            setIsLoading(false)
            setBuildError(true)
            // console.log('!!!!! error on ai response. passed message was: ', message)
            // console.log('AIstep was: ', AIstep)
            // console.log('revision was: ', newUpdate)
            // console.log('prompt was: ', prompt)
            alert(response.error.error.error?.message)
            // console.log(response.error.error.error.message)
            return responseInfo
        }
        else responseInfo = { plan: response?.data?.output?.plan || [], tokens: response?.data?.usage }
        return responseInfo
    }


    const addCrossFitTraining = async (userDetails: any) => {

        const crossfitStepData = []

        setFetchingAIresults(true)
        const race_day = moment(userDetails["1-0"]?.raceDate).format('dddd')

        let latestResults: any[] = []
        let usedTokens = []
        userDetails["0"].currentStep = 4

        displayLogs && console.log('>>> adding crossfit training')
        // console.log('>>> crosstraining: ', userDetails["4"])
        const mergedUserDetails = { ...userDetails["0"], ...userDetails["1-0"], ...userDetails["1-1"], ...userDetails["1-2"], ...userDetails["2"], ...userDetails["3"], ...userDetails["4"] }

        // saving for admin-dash
        // commented out as its now saving during the step itself
        // console.log('>>> mergedUserDetails stepResults: ', mergedUserDetails.stepResults)
        mergedUserDetails.stepResults.map((e: any) => {
            const dailiesReruns = ' [' + (userDetails["0"].dailiesReruns || 0) + ']'
            const weekliesReruns = ' [' + (userDetails["0"].weekliesReruns || 0) + ']'
            setDataGenerated({ type: "step " + e.step + (e.step == 2 ? weekliesReruns : '') + (e.step == 3 ? dailiesReruns : ''), data: e.data })
            // stepsDataGenerated.push({ type: "step " + e.step + (e.step == 2 ? weekliesReruns : '') + (e.step == 3 ? dailiesReruns : ''), data: e.data })
            crossfitStepData.push({ type: "step " + e.step + (e.step == 2 ? weekliesReruns : '') + (e.step == 3 ? dailiesReruns : ''), data: e.data })
        })

        // check if user has crossfit and stretching plan. skip if none
        if ((mergedUserDetails.crossTraining && mergedUserDetails.crossTraining.length > 0) || (mergedUserDetails.stretchingPlan && mergedUserDetails.stretchingPlan.length > 0)) {

            const prompt = buildStage2InputPromptsForRunner({ ...mergedUserDetails, race_day: race_day })

            displayLogs && console.log(' >>> final mergedUserDetails: ', mergedUserDetails)
            displayLogs && console.log(' >>> crossfit prompt: ', prompt[2])

            const message = [{
                "role": "system",
                "content": defaultSytemPrompt
            }, {
                "role": "user",
                "content": prompt[2]
            }]

            const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
            const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o', gptTemp: '0' })
            if (response && response.status === "error") {
                setIsLoading(false)
                setBuildError(true)
                alert(response.error.error.error?.message)
                return latestResults || []
            }

            latestResults = response?.data?.output?.plan || []
            usedTokens = response?.data?.usage || []
            displayLogs && console.log('>>> crossfit AI results: ', response?.data?.output?.plan)

            latestResults = handleXTresultsFormat(latestResults, mergedUserDetails.planWeeks)
            displayLogs && console.log('>>> crossfit handled results: ', handleXTresultsFormat(latestResults, mergedUserDetails.planWeeks))

            // append with previous results
            latestResults = appendCrossTrainingData(mergedUserDetails.stepResults.find((e: any) => e.step === userDetails["0"].currentStep)?.data, latestResults)
            displayLogs && console.log('>>> final built plan: ', latestResults)

        } else {
            // will just add none on cross training
            latestResults = appendCrossTrainingData(mergedUserDetails.stepResults.find((e: any) => e.step === userDetails["0"].currentStep)?.data, latestResults)
        }

        // if (TESTPLANRESULTS.length > 0) latestResults = TESTPLANRESULTS
        // displayLogs && console.log('>>> TEST PLAN: ', latestResults)


        if (latestResults) {
            // console.log('done adding crossfit training')
            setFetchingAIresults(false)
            setBuildStepAI((prev: number) => prev + 1)
        }

        setDataGenerated({ type: 'final', data: { modified: latestResults, unmodified: latestResults, tokens: usedTokens } })
        // stepsDataGenerated.push({ type: 'final', data: { modified: latestResults, unmodified: latestResults, tokens: usedTokens } })
        crossfitStepData.push({ type: 'final', data: { modified: latestResults, unmodified: latestResults, tokens: usedTokens } })


        userDetails["0"].stepsDataGenerated = [...stepsDataGenerated, ...crossfitStepData]


        return latestResults
    }

    useEffect(() => {
        const test = true
        if (stage1 && stage2 && !test) {
            setIsLoading(true)
            const userDetailsPrompt = buildUserDetails(stage1)
            stage1AICall(userDetailsPrompt)
        }
    }, [])

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => { !isOnline && setTimeout(() => { !isOnline && setBuildError(true) }, 15000) }, [isOnline, buildError])

    useEffect(() => { displayLogs && console.log('logging more info') }, [displayLogCounter])


    return { finalPlan, isLoading, fetchingAIresults, setFetchingAIresults, stage3aAICall, buildTablePlanOverview, buildTablePlanOverviewV2, addCrossFitTraining, buildStepAI, buildError, setBuildError, setDisplayLogCounter }
}

export default useRSPlanAIv2;





const TESTPLANRESULTS: any[] = []