/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowLeft, ArrowRight, ReplayRounded, SendRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import useSWR from 'swr'
import Loader from '../../components/animation/Loader'
import RSButton from '../../components/buttons/RSButton'
import RSDateInput from '../../components/input/RSDateInput'
import RSInputAdornment from '../../components/input/RSInputAdornment'
import { postReq } from '../../modules/apiConsume'
import ChatCompletionPrompts, { AIUserDetails, Stage2Details } from '../../modules/ChatCompletionPrompts'
import SimplifiedSystemPrompt from '../../modules/SimplifiedSystemPrompt'
import SystemPromptFormat from '../../modules/SystemPrompFormat'
import { AuthPageProps } from '../../modules/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JSONTable from './JSONTable'
import current from 'locutus/php/array/current'
import useManualCalculations from './useManualCalculations'
import { once } from 'events'
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../training-plan/build/DaysOptions'
const INITIAL_VAKLUE = `Build Out a 13-week running plan for a 38 year old male runner. He's currently averaging 20 miles a week. His longest run in the last 2 weeks is 6 miles.`

const BOILERPLATE_PROMPT = 'Build a training plan for a half marathon (13.1 miles) with all the steps and rules mentioned. '

interface IMessage {
    role: string
    content: string
}

const ChatCompletionStep: FC<AuthPageProps> = ({ userInfo }) => {

    const { data: accountProfile, isLoading: accountProfileLoading } = useSWR(userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })

    const { dob, measurement_system, running_activity, gender } = accountProfile?.data?.result[0] || {}

    const gptModels = ['gpt-3.5-turbo-0125', 'gpt-4o']
    const defaultUserDetails: AIUserDetails = {
        planWeeks: 0,
        dob: dob && dob || '1986/01/01',
        gender: gender || 'Male',
        currentWeeklyMileage: 20,
        unit: 'miles',
        lastLongestRun: 6,
        maxRunPerWeek: 30
    }

    const stage2DefaultUserDetails: Stage2Details = {
        preferredLongRun: 'Saturday',
        availableRunDays: ['Sunday', 'Tuesday', 'Thursday', 'Saturday'],
        runDaysPerWeek: 4
    }

    const raceTypeSelection = ['Full', 'Half', '10k', '5k']
    const weekEnds = ['Saturday', 'Sunday']

    const { applyRecoveryWeeks, applyTaperWeeks, getWithHighestWeeklyMileage, getLongRuns, assignLongRunDay, assignRunType, getDailyRuns, reArrangeMileage } = useManualCalculations()
    const { defaultSytemPrompt, inputPrompts, buildStage2InputPrompts, buildUserDetails } = ChatCompletionPrompts()
    const usedPromptSteps: string | any[] = [BOILERPLATE_PROMPT]
    const [userDetails, setUserDetails] = useState<AIUserDetails>(defaultUserDetails)
    const [results, setResults] = useState<any>()
    const [formText, setFormText] = useState(buildUserDetails(userDetails))
    const [systemPrompt, setSystemPrompt] = useState(defaultSytemPrompt)
    const [isLoading, setIsLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [messages, setMessages] = useState<IMessage[]>([])
    const [isSinglePrompt, setIsSinglePrompt] = useState(false)
    const [systemPromptsSteps, setSystemPromptsSteps] = useState([''])
    const [runningActivity, setRunningActivity] = useState(running_activity?.split(' ')?.[0] || '10');
    const [longestRecentRun, setLongestRecentRun] = useState('6');
    const [raceType, setRaceType] = useState('Half')
    const [maxDistance, setMaxDistance] = useState('20');
    const [planWeeks, setPlanWeeks] = useState('16');
    const [dateValue, setDateValue] = useState<any>(defaultUserDetails?.dob || undefined);
    const isMetric = measurement_system === 'metric' ? true : false
    const metricLabel = isMetric ? 'KM' : 'MI'
    const metricArial = isMetric ? 'kilometers' : 'miles'
    const [longRunDay, setLongRunDay] = useState('Saturday')
    const [dailyRunTable, setDailyRunTable] = useState(false)
    const [selectedGPTModel, setSelectedGPTModel] = useState(gptModels[0])
    const [gptTemp, setGPTTemp] = useState('0')
    const [initLongRuns, setInitLongRuns] = useState([])

    let daysOfWeek = longRunDay === 'Sunday' ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
    daysOfWeek = daysOfWeek.filter((day) => day !== longRunDay)
    const [availableDays, setAvailableDays] = useState<Array<string>>([])
    const retryAttempts = 3;

    const stage2InputPrompts = buildStage2InputPrompts({ preferredLongRun: longRunDay, availableRunDays: availableDays, runDaysPerWeek: availableDays.length, raceType, samplePlan: results, dob: dateValue, gender: userDetails?.gender || 'male', initLongRuns: initLongRuns })

    const inputPrompstValues = [buildUserDetails(userDetails), ...stage2InputPrompts]

    const handleSubmit = async () => {
        setIsLoading(true)
        const msgs = currentStep !== 1 ? [...messages, { role: 'user', content: formText }] : messages
        console.log('messages', msgs)
        let curResults: any = []
        if (currentStep === 1) {
            for (let attempt = 1; attempt <= retryAttempts; attempt++) {
                console.log('Fetching results attempt: ', attempt)
                const response = await postReq('/v2/openai/chat-completion', { messages: JSON.stringify(msgs), gptModel: selectedGPTModel, gptTemp: gptTemp })
                if (response && response.status === "error") {
                    setIsLoading(false)
                    if (attempt == retryAttempts) alert(response.error.error.error.message)
                    continue
                }

                const responseValid = validateResponse(response?.data?.output?.plan)
                if (!responseValid) continue
                setIsLoading(false)
                curResults = getLongRuns(applyRecoveryWeeks(applyTaperWeeks(getWithHighestWeeklyMileage(response?.data?.plan), raceType), raceType) || [], raceType, Number(maxDistance || 0), Number(longestRecentRun || 0), 0, false) || []

                const longruns: any = []
                curResults.forEach((e: any) => {
                    longruns.push({ w: e.Week, lr: e.Long_run })
                });

                setInitLongRuns(longruns)
                break
            }
        }
        // else if (currentStep === 2) {

        //     setDailyRunTable(true)
        //     const dailyRuns = getDailyRuns(results, { long_run_day: longRunDay, run_days: availableDays })
        //     console.log(dailyRuns)
        //     curResults = { plan: assignRunType(assignLongRunDay(dailyRuns, longRunDay), longRunDay) }

        // }
        else {
            const response = await postReq('/v2/openai/chat-completion', { messages: JSON.stringify(msgs), gptModel: selectedGPTModel, gptTemp: gptTemp })
            if (response && response.status === "error") {
                setIsLoading(false)
                alert(response.error.error.error.message)
                return
            }

            curResults = response?.data?.output || []
            if (currentStep === 2) {
                curResults = { plan: reArrangeMileage(curResults.plan) }
            }

        }
        setResults(currentStep === 1 ? curResults : curResults.plan)
        handleNextStep()
        setMessages([...msgs, { role: 'assistant', content: JSON.stringify(currentStep === 1 ? { plan: curResults } : curResults) }])
        setIsLoading(false)

    }

    const handleChange = (event: any) => {
        setRaceType(event.target.value)
    }
    const handleNextStep = () => {
        console.log('currentStep', currentStep)
        if (currentStep < 0)
            setCurrentStep(1)
        else
            setCurrentStep(currentStep + 1)
    }

    const handleBackStep = () => {
        if (currentStep === 1) return
        setCurrentStep(currentStep - 1)
    }

    const handleReset = () => {
        setResults([])
        setInitLongRuns([])
        setMessages([{ role: 'system', content: systemPrompt }, { role: 'user', content: formText }])
        setCurrentStep(1)
        setDailyRunTable(false)
    }


    const validateResponse = (tableData: any) => {

        let valid = true
        tableData.forEach((week: any) => {
            if (week.Weekly_mileage > maxDistance) {
                valid = false
                return
            }
        })
        return valid
    }



    useEffect(() => {
        if (currentStep == 1)
            setFormText(inputPrompstValues[0])
        // else if (currentStep == 2)
        //     setFormText(`Assign long run day to the plan, selected day is ${longRunDay}`)
        else if (currentStep > inputPrompstValues.length)
            setFormText('')
        else
            setFormText(inputPrompstValues[currentStep - 1])
    }, [currentStep, userDetails, availableDays, longRunDay, raceType, planWeeks, runningActivity, longestRecentRun, maxDistance, dateValue, selectedGPTModel])

    // useEffect(() => {
    //     if (currentStep == 2)
    //         setFormText(`Assign long run day to the plan, selected day is ${longRunDay}\n\nSelected days for running are ${availableDays.join(', ')}`)
    // }, [currentStep, longRunDay, availableDays])

    useEffect(() => {
        console.log('messages', messages)
    }, [messages])

    useEffect(() => {
        if (currentStep == 1) {
            setMessages(() => [{ role: 'system', content: systemPrompt }, { role: 'user', content: formText }])
        }
    }, [formText])

    useEffect(() => {
        setUserDetails({
            ...userDetails,
            dob: dateValue,
            lastLongestRun: Number(longestRecentRun || 0),
            maxRunPerWeek: Number(maxDistance || 0),
            currentWeeklyMileage: Number(runningActivity || 0),
            raceType: raceType,
            planWeeks: Number(planWeeks || 0)
        })
    }, [dateValue, runningActivity, longestRecentRun, maxDistance, raceType, planWeeks])

    useEffect(() => {
        setFormText(buildUserDetails(userDetails))
        // inputPrompstValues = [buildUserDetails(userDetails), ...buildStage2InputPrompts({ preferredLongRun: longRunDay, availableRunDays: availableDays, runDaysPerWeek: availableDays.length, raceType, samplePlan: results, dob: dateValue, gender: userDetails?.gender || 'male' })]
    }, [userDetails])

    return (
        <Grid container spacing={1} xs={12}>
            <Grid item xs={12}>
                {/* <Select className='w-100' placeholder='Select Program' value={selectedPrompt} onChange={(e) => handleChange(e)} >
                    {selection.map((s, index) => (<MenuItem key={index} value={s?.id}>{s.name}</MenuItem>))}
                </Select> */}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        System Prompt - Steps
                    </AccordionSummary>
                    <AccordionDetails>
                        <Form.Control
                            value={systemPrompt}
                            type="text"
                            as="textarea"
                            rows={11}
                            placeholder="System Prompt"
                            onChange={(e) => setSystemPrompt(e.target.value)}
                            // onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            // onBlur={() => handleUpdateSale("subtext")}
                            disabled={isLoading}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} xs={12}>
                    <Grid item xs={3}>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                            <Form.Label className='mt-3'>
                                User Details
                            </Form.Label>
                            <Box>
                                <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }}>Date of birth</Box>
                                <Grid container xs={12} sx={{ maxWidth: '120px' }}>
                                    <RSDateInput date={dateValue} setDate={setDateValue} maxDate={moment()} />
                                </Grid>
                                <Grid container>
                                    {/* to change to dropdown selection */}
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Select Race Type</InputLabel>
                                    <Select className='w-100' placeholder='Select Program' value={raceType} onChange={(e) => handleChange(e)} >
                                        {raceTypeSelection.map((rt, index) => (<MenuItem key={index} value={rt}>{rt}</MenuItem>))}
                                    </Select>
                                </Grid>

                                <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Plan Length (weeks)`}
                                </Grid>
                                <Grid container sx={{ maxWidth: '120px' }}>
                                    <RSInputAdornment value={planWeeks} setValue={setPlanWeeks} label={''} arial={metricArial} />
                                </Grid>

                                <Grid container>
                                    <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                        {`Avg. ${metricArial} in four weeks?`}
                                    </Grid>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={runningActivity} setValue={setRunningActivity} label={metricLabel} arial={metricArial} />
                                    </Grid>
                                    <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                        What is your longest run?
                                    </Grid>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={longestRecentRun} setValue={setLongestRecentRun} label={metricLabel} arial={metricArial} />
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sx={{ pt: '10px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Maximum distance in one week?`}
                                </Grid>
                                <Grid container sx={{ maxWidth: '120px' }}>
                                    <RSInputAdornment value={maxDistance} setValue={setMaxDistance} label={metricLabel} arial={metricArial} />
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    What do you prefer for your long run?
                                </Grid>
                                <Grid container xs={12} sx={{ maxWidth: '210px', pt: 1 }}>
                                    {weekEnds.map((day: string, index: number) => {
                                        const isActive = longRunDay === day
                                        return <Box key={index + day + '-lr'} sx={{ p: '0px 1px', height: '50px', width: '100px' }}>
                                            <Button onClick={() => setLongRunDay(day)} sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101' }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{day}</Box>
                                            </Button>
                                        </Box>
                                    })}
                                </Grid>

                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Select all days you're able to run`}
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 1 }}>
                                    {daysOfWeek?.map((day: string, index: number) => {
                                        const slicedDay = day.slice(0, 3)
                                        const isActive = availableDays?.includes(day)
                                        return <Grid container key={index + day} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
                                            <Button onClick={() => isActive ? setAvailableDays(availableDays.filter((ad: string) => ad !== day)) : setAvailableDays([...availableDays, day])}
                                                sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101', px: 0 }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{slicedDay}</Box>
                                            </Button>
                                        </Grid>
                                    })}
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                            <Grid container spacing={1}>
                                <Grid xs={8} md={8} item>
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Select GPT Model</InputLabel>
                                    <Select className='w-100' placeholder='Select Program' value={selectedGPTModel} onChange={(e) => setSelectedGPTModel(e.target.value)} >
                                        {gptModels.map((gpt, index) => (<MenuItem key={index} value={gpt}>{gpt}</MenuItem>))}
                                    </Select>
                                </Grid>
                                <Grid xs={4} md={4} item>
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Temperature</InputLabel>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={gptTemp} setValue={setGPTTemp} label={''} arial='' />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12} md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            History of Conversation
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {messages.length > 0 ? (
                                                <Table responsive striped bordered hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Role</th>
                                                            <th>Message</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {messages.map((msg, i) => (
                                                            <tr key={i}>
                                                                <td>{msg.role}</td>
                                                                <td>{msg.content}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            ) : undefined}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Form.Label className='mt-3'>
                                {/* {currentStep < 0 ? 'User Input' : `Step ${currentStep} of ${inputPrompstValues.length}`} */}
                                User Input
                            </Form.Label>

                            <Form.Control
                                value={formText}
                                type="text"
                                as="textarea"
                                rows={18}
                                placeholder=""
                                onChange={(e) => setFormText(e.target.value)}
                                // onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                                // onBlur={() => handleUpdateSale("subtext")}
                                disabled={isLoading}
                            />
                            <Grid container spacing={1}>
                                <Grid xs={3} item><RSButton disabled={isLoading} className='mt-2' onClick={handleReset}> <ReplayRounded />Reset </RSButton></Grid>
                                <Grid xs={3} item><RSButton disabled={isLoading} className='mt-2' onClick={handleBackStep}><ArrowLeft /> Back </RSButton></Grid>
                                <Grid xs={3} item><RSButton disabled={isLoading || !formText} className='mt-2' onClick={handleNextStep}> Next <ArrowRight /></RSButton></Grid>
                                <Grid xs={3} item><RSButton disabled={isLoading || !availableDays.length} className='mt-2' onClick={handleSubmit}><SendRounded />  {!isSinglePrompt && currentStep > 0 ? 'Submit' : 'Submit'} </RSButton></Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                    {initLongRuns.length > 0 ? (
                        <>
                            <h5>Long Runs</h5>
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        {initLongRuns.map((x: any, i: number) => (
                                            <th key={i}>w {x.w}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {initLongRuns.map((week: any, i: number) => (
                                            <td key={i}>{week.lr}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    ) : undefined}
                    {/* <div><pre>{JSON.stringify(results, null, 2)}</pre></div> */}
                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                {!dailyRunTable ? results?.length > 0 && Object.keys(results[0]).map((x: any, i: number) => (
                                    <th key={i}>{x.replaceAll('_', ' ')}</th>
                                ))
                                    : <>
                                        <th>Week</th>
                                        <th>Weekly Mileage</th>
                                        {results?.length > 0 && results[0].days.map((day: any, dI: number) => <>
                                            <th>{day.name}</th>
                                            {/* <th>Type</th> */}
                                        </>)}
                                    </>
                                }

                                {/* <th>Week</th>
                                <th>Long Run (mi)</th>
                                <th>Weekly Mileage</th>
                                <th>Taper</th>
                                <th>Recovery</th>
                                {results?.length > 0 && results[0]?.daily_runs?.map((x: any, i: number) => (
                                    <th key={i}>{x?.day}</th>
                                ))} */}
                            </tr>
                        </thead>
                        <tbody>
                            {!dailyRunTable ? results?.map((data: any, i: number) => (
                                <tr key={i}>
                                    {Object.values(data).map((x: any, i: number) => (
                                        <td key={i}>{String(x) == "false" ? '' : typeof x === "object" ? (
                                            <JSONTable noHeader={true} data={x} />
                                        ) : String(x)}</td>
                                    ))}
                                </tr>

                            ))
                                : results?.map((week: any, wI: number) =>
                                    <tr key={wI}>

                                        <td>{week.week}</td>
                                        <td>{week.weekly_mileage}</td>

                                        {week.days.map((day: any, dI: number) => <>
                                            <td>{day.long_run} {day.long_run != 0 && day.run_type ? '-' : ''} {day.long_run != 0 && day.run_type}</td>

                                        </>)}
                                    </tr>
                                )
                            }


                            {/* {results?.map((data: any, i: number) => (
                                <tr key={i}>
                                    <td>{data.week}</td>
                                    <td>{data.long_run}</td>
                                    <td style={{ minWidth: '160px' }}>{data.Weekly_mileage}</td>
                                    <td style={{ minWidth: '160px' }}>{data.is_taper ? 'YES' : ''}</td>
                                    <td style={{ minWidth: '160px' }}>{data.is_recovery ? 'YES' : ''}</td>
                                    {data?.daily_runs?.map((x: any, i: number) => (
                                        <td key={i}>{x?.run}</td>
                                    ))}
                                </tr>
                            ))} */}
                        </tbody>
                    </Table>
                </div>
                <Loader active={isLoading} />
            </Grid>
        </Grid>
    )
}

export default ChatCompletionStep
