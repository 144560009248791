import { IFeaturedProgram } from "../api/v2/favorites/types"
import { StoreSlice } from "./types"

export interface IToast {
    show: boolean,
    title?: string,
    message?: string,
    variant?: string,
    onClose?: () => void,
    duration?: number
}
export interface IComponentStore {
    toast: IToast
    setToast: (toastData: IToast) => void
    isMobile: boolean
    setIsMobile: (bool: boolean) => void
    isLoading: boolean
    setIsLoading: (bool: boolean) => void
    isSuccess: boolean
    setIsSuccess: (bool: boolean) => void
    loadingMsg?: string
    setLoadingMsg: (message: string | undefined) => void
    showAnnouncementPopup: boolean
    setShowAnnouncementPopup: () => void
    resetCurrentDay: number
    setResetCurrentDay: (day: number) => void
    resetCurrentDate: any
    setResetCurrentDate: (date: any) => void
    selectedProgram: IFeaturedProgram | undefined
    setSelectedProgram: (program: IFeaturedProgram | undefined) => void
    selectedProgramType: string | undefined
    setSelectedProgramType: (type: string | undefined) => void
    showAccountSideMenu: boolean
    setShowAccountSideMenu: (bool: boolean) => void,
    showSideMenu: boolean
    setShowSideMenu: (bool: boolean) => void,
    selectedAccountPage: number | undefined,
    setselectedAccountPage: (page: number | undefined) => void
    currentAppVersion: string | undefined,
    setCurrentAppVersion: (version: string | undefined) => void,
    lastRNBridgeMessage: string | undefined,
    setLastRNBridgeMessage: (lastRNBridgeMessage: string | undefined) => void
}

const createComponentStore: StoreSlice<IComponentStore> = (set) => ({
    toast: { show: false },
    setToast: (toastData) => set(() => ({ toast: toastData })),
    isMobile: false,
    setIsMobile: (bool) => set(() => ({ isMobile: bool })),
    isLoading: false,
    setIsLoading: (bool) => set(() => ({ isLoading: bool })),
    isSuccess: false,
    setIsSuccess: (bool) => set(() => ({ isSuccess: bool })),
    loadingMsg: undefined,
    setLoadingMsg: (message) => set(() => ({ loadingMsg: message })),
    showAnnouncementPopup: false,
    setShowAnnouncementPopup: () => set((state) => ({ showAnnouncementPopup: !state.showAnnouncementPopup })),
    resetCurrentDay: 0,
    setResetCurrentDay: (day) => set(() => ({ resetCurrentDay: day })),
    resetCurrentDate: 0,
    setResetCurrentDate: (date) => set(() => ({ resetCurrentDate: date })),
    selectedProgram: undefined,
    setSelectedProgram: (program) => set(() => ({ selectedProgram: program })),
    selectedProgramType: undefined,
    setSelectedProgramType: (type) => set(() => ({ selectedProgramType: type })),
    showAccountSideMenu: false,
    setShowAccountSideMenu: (bool) => set(() => ({ showAccountSideMenu: bool })),
    showSideMenu: false,
    setShowSideMenu: (bool) => set(() => ({ showSideMenu: bool })),
    selectedAccountPage: undefined,
    setselectedAccountPage: (page) => set(() => ({ selectedAccountPage: page })),
    currentAppVersion: undefined,
    setCurrentAppVersion: (version) => set(() => ({ currentAppVersion: version })),
    lastRNBridgeMessage: undefined,
    setLastRNBridgeMessage: (lastRNBridgeMessage) => set(() => ({ lastRNBridgeMessage: lastRNBridgeMessage })),
})

export default createComponentStore