import { checkdate, date, strtotime, time } from "locutus/php/datetime";
import moment from "moment";
import { intval } from "locutus/php/var";
import { ITimeObject } from "../api/v2/types";

const HALF_MARATHON = 13.1094;
const MARATHON = 26.2188;
const TENK = 6.21371192237;
const EIGHTK = 4.97096944;
const FIVEK = 3.10686;

const useTrainingPlanUtils = () => {

    const convertDateToUTC = (date: Date) => {
        return moment.utc(date).format("MM/DD/YYYY")
    }

    const isLateStart = (date: string, weeks: number) => {

        const chosenWeeks = `-${weeks} week`;
        const dateSecs = strtotime(date);
        const dateNew = strtotime(chosenWeeks, dateSecs)

        const now = time();
        const datediff = now - dateNew;
        const diff = Math.floor(datediff / (60 * 60 * 24));
        if (diff >= 0)
            return true
        else
            return false
    }

    //DATES -----------------------------------------------------------------------------

    const getWeekDays = (current_date: Date, start_date: Date, end_date: Date, weekdays: Array<any>) => {
        const dateArr = [];
        const weekday = weekdays[0];
        const start = strtotime(convertDateToUTC(start_date));
        const end = strtotime(convertDateToUTC(end_date));
        let ittDay = strtotime(weekday, start);
        let cont = true;
        while (cont) {
            dateArr.push((date("Y-m-d", ittDay)));
            ittDay = strtotime("+1 weeks", ittDay);
            if (ittDay > end) {
                cont = false
                break;
            }
        }
        return dateArr;
    }

    const getCurrentWeek = (dateVal: Date, isStartMonday: boolean) => {

        let realStart;

        if (isWeekend(dateVal)) {
            if (isStartMonday) {
                realStart = strtotime('next Monday', strtotime(convertDateToUTC(dateVal)));
            } else {
                realStart = strtotime('next Sunday', strtotime(convertDateToUTC(dateVal)));
            }
            //realStart = strtotime('next Sunday', strtotime(date));
        } else {
            if (!isStartMonday) {
                realStart = strtotime(convertDateToUTC(dateVal));
            }

            // Sunday start date, Monday weeks, current week ahead by 1 on Sundays: FIX

            else {
                realStart = strtotime('next Monday', strtotime(convertDateToUTC(dateVal)));
            }
        }

        //realStart is week1 day1.
        const daysBetween = DaysBetween(realStart);
        const weeksBetween = Math.floor(daysBetween / 7);
        return weeksBetween;
    }

    const isWeekend = (dateVal: Date) => {
        if (date('N', strtotime(convertDateToUTC(dateVal))) == 7) {
            return true;
        }
        return false;
    }

    const getCurrentDay = (isMondayStart: boolean) => {
        let thisDay = date("w");
        thisDay = isMondayStart ? (thisDay > 0 ? thisDay - 1 : 6) : thisDay;
        return thisDay;
    }

    const getThisDate = (dateVal: Date, week: number, thisDay: number, isMondayStart: boolean) => {

        let realStart;
        const dateValue = convertDateToUTC(dateVal)
        if (isWeekend(dateVal)) {
            if (isMondayStart) {
                realStart = strtotime('next Sunday', strtotime(dateValue));
            } else {
                realStart = strtotime('next Sunday', strtotime(dateValue));
            }
        } else {
            realStart = strtotime(dateValue);
        }
        week -= 1;
        let days = week * 7 + +thisDay;
        days = date("F d, Y", strtotime(`+${days} days`, realStart));

        return days;
        //return date("Y-m-d", days);
    }

    const DaysBetween = (date: number) => {
        const now = time();
        const datediff = now - date;
        return Math.floor(datediff / (60 * 60 * 24));
    }

    const DaysBetween2 = (date1: number, date2: number) => {

        const diff = date2 - date2;
        const TotalDays = Math.ceil(diff / (1000 * 3600 * 24));
        // if (TotalDays >= 0)
        //     return `+{TotalDays}`
        // else
        //     return `-{TotalDays}`;
        return TotalDays;
    }

    const DaysBetweenCalendar = (date1: any, date2: any) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // const a = new Date(date1 * 1000);
        // const b = new Date(date2 * 1000)
        // const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        // const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        // const diff = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        const dt1 = moment.utc(date1);
        const dt2 = moment.utc(date2);

        const result = intval(dt2.diff(dt1) / _MS_PER_DAY * 1000)
        // console.log(result - (result * 2))
        return result - (result * 2)

    }

    const getRealStart = (datum: Date, isStartMonday: boolean) => {
        let realStart;
        const dateReal = convertDateToUTC(datum)
        if (isWeekend(datum)) {
            if (isStartMonday) {
                realStart = strtotime('next Monday', strtotime(dateReal));
            } else {
                realStart = strtotime('next Sunday', strtotime(dateReal));
            }
        } else {
            realStart = strtotime(dateReal);
        }
        return realStart;
    }

    const GetWeek = (dateVal: string, raceDate: Date, isMondayStart: boolean) => {
        let realStart;
        const realRaceDate = convertDateToUTC(raceDate)
        if (isWeekend(raceDate)) {
            if (isMondayStart) {
                realStart = strtotime('next Monday', strtotime(realRaceDate));
            } else {
                realStart = strtotime('next Sunday', strtotime(realRaceDate));
            }
        } else {
            realStart = strtotime(realRaceDate);
        }
        if (realStart == 1488085200) {
            realStart = 1488081200;
        }
        const date = strtotime(dateVal);
        const days_between = DaysBetweenCalendar(date, realStart);
        const weeks_between = Math.floor(days_between / 7);

        return weeks_between;
    }

    const GetWeek2 = (dateVal: string, raceDate: Date) => {
        const realStart = strtotime(convertDateToUTC(raceDate))

        const date = strtotime(dateVal);
        const days_between = DaysBetweenCalendar(date, realStart);
        const weeks_between = Math.floor(days_between / 7);

        return weeks_between;
    }

    const DaysInMonth = (month: number, year: number) => {
        if (checkdate(month, 31, year)) {
            return 31;
        }
        if (checkdate(month, 30, year)) {
            return 30;
        }
        if (checkdate(month, 29, year)) {
            return 29;
        }
        if (checkdate(month, 28, year)) {
            return 28;
        }
        return 0; // error
    }

    // CONVERSIONS -------------------------------------------------------------------------


    const ConvertH2M = (hr: number, min: number, sec: number) => {
        let convertedTime = hr * 60;
        const s = sec / 60;

        convertedTime += min + s;
        return convertedTime;
    }

    const ConvertM2Mt = (miles: number) => {
        const m2k = 1.60934;
        return m2k * miles * 1000;
    }

    const ConvertM2H2 = (time: number) => {
        let hours = Math.floor(time / 60);
        let minutes = (time - (hours * 60));
        let seconds = (minutes - Math.floor(minutes)) * 60;
        minutes = Math.floor(minutes);
        seconds = Math.round(seconds);
        if (seconds >= 60) {
            seconds = 0;
            minutes++;
        }
        if (minutes >= 60) {
            minutes = 0;
            hours++;
        }
        if (hours == 0) {
            return `${minutes}m ${seconds}s`;
        }
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    const ConvertSecondsToObject = (time: number): ITimeObject => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const seconds = time - (hours * 3600) - (minutes * 60);

        return {
            hr: hours,
            min: minutes,
            sec: seconds
        }
    }

    const ConvertM2H = (time: number) => {
        const hours = Math.floor(time / 60);
        let minutes = (time - (hours * 60));
        let seconds = (minutes - Math.floor(minutes)) * 60;
        minutes = Math.floor(minutes);
        seconds = Math.round(seconds);
        if (seconds >= 60) {
            seconds = 0;
            minutes++;
        }
        return `${minutes}m ${seconds}s`;
    }

    const ConvertT2MS = (time: number) => {
        let minutes = time;
        let seconds = (minutes - Math.floor(minutes)) * 60;
        minutes = Math.floor(minutes);
        seconds = Math.round(seconds);
        if (seconds >= 60) {
            seconds = 0;
            minutes++;
        }
        return `${minutes}m ${seconds}s`;
    }

    const ConvertS2M = (time: number) => {
        let minutes = Math.floor(time / 60);
        let seconds = (time - (minutes * 60));
        seconds = Math.round(seconds);
        if (seconds >= 60) {
            seconds = 0;
            minutes++;
        }
        return `${minutes}m ${seconds}s`;
    }

    const ConvertFullToHalf = (val: number) => {
        const timeM = val * Math.pow((13.1094 / 26.2188), 1.06);
        return ConvertM2H2(timeM);
    }

    const ConvertFullTo5k = (val: number) => {
        const timeM = val * Math.pow((3.10686 / 26.2188), 1.06);
        return ConvertM2H2(timeM);
    }

    const ConvertFullto10k = (val: number) => {
        const timeM = val * Math.pow((TENK / 26.2188), 1.06);
        return ConvertM2H2(timeM);
    }

    const ConvertFullto8k = (val: number) => {
        const timeM = val * Math.pow((EIGHTK / 26.2188), 1.06);
        return ConvertM2H2(timeM);
    }

    const ConvertS2TimeObject = (time: number) => {
        let resultTime = time ? time : 0
        const hr = resultTime && resultTime > 3599 ? Math.floor(resultTime / 3600) : 0
        resultTime = hr ? resultTime - (hr * 3600) : resultTime
        const min = resultTime && resultTime > 59 ? Math.floor(resultTime / 60) : 0
        resultTime = min ? resultTime - (min * 60) : resultTime
        const sec = resultTime ? resultTime : 0
        return { hr, min, sec }
    }

    //Calculations -------------------------------------------------------------------------

    const getCurrentVDOT = (w: number, d: number, vd: number, tm: number, adj: Array<any>) => {
        if (adj?.length <= 0) {
            return { vdot: vd, targetm: tm }
        } else {
            let curVd = 0;
            let curTm = 0;
            let savedId = 0
            for (let i = 0; i < adj?.length; i++) {
                const dateCalc = (w * 7) + d;
                const adjWeek = adj[i]["week"];
                const adjDay = adj[i]["day"];
                const newId = adj[i]?.id
                const adjCalc = (adjWeek * 7) + adjDay;
                if (dateCalc >= adjCalc && newId > savedId) {
                    savedId = newId
                    curVd = adj[i]["vdot"];
                    curTm = adj[i]["targetm"];
                }
            }
            if (curVd != 0 && curTm != 0) {
                return { vdot: curVd, targetm: curTm }
            } else {
                return { vdot: vd, targetm: tm }
            }
        }
    }


    const CalcvDOT = (dist: number, time: number) => {
        const percent_max = 0.8 + 0.1894393 * Math.exp(-0.012778 * time) + 0.2989558 * Math.exp(-0.1932605 * time);
        const vo2 = -4.6 + 0.182258 * (dist / time) + 0.000104 * Math.pow((dist / time), 2);
        const val = (vo2 / percent_max);
        return val.toFixed(1);
    }

    const ComputeAgg = (agg: number) => {
        if (agg != 0) {
            agg = 1 + (agg / 100);
        }
        return agg;
    }

    const computeKmPace = (pace: number) => {
        return pace / 1.60934;
    }


    const convertDistance = (num: number) => {
        // return num.toFixed(0).toString().replace(/(\.0+|0+)$/, '')
        return num?.toFixed(1).toString().replace(/(\.0+|0+)$/, '')
    }


    // CALCULATE PACES
    const CalcBPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = 0.915 * (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.67 * vdot)));
        dist = dist * 0.000621371;
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }

        return ConvertM2H(pace)
    }

    const CalcRPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 1.08 * vdot)));
        dist = dist * 0.000621371;
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const CalcIPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.975 * vdot)));
        dist = dist * 0.000621371;
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const CalcTPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.88 * vdot)));
        dist = dist * 0.000621371;
        if (dist != 0) {
            pace = pace / dist;
            if (isKm) {
                pace = computeKmPace(pace);
            }
            return ConvertM2H(pace);
        }
        else
            return 0;
    }

    const CalcEPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.67 * vdot)));
        dist = dist * 0.000621371;
        if (dist == 0)
            dist = 1
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const CalcLPace = (dist: number, vdot: number, isKm: boolean) => {
        let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.7 * vdot)));
        dist = dist * 0.000621371;
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const CalcMPace = (dist: number, timeM: number, isKm: boolean) => {
        let pace = dist * (timeM / MARATHON);
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const CalcMiscPace = (dist: number, timeM: number, isKm: boolean) => {
        let pace = timeM * Math.pow((dist / MARATHON), 1.06);
        pace = pace / dist;
        if (isKm) {
            pace = computeKmPace(pace);
        }
        return ConvertM2H(pace);
    }

    const ConvertMetersToMiles = (M: number) => {
        return M * 0.000621371
    }

    const getGarminPace = (isKm: boolean, speed: number) => {
        const kpm = (speed * 60) / 1000
        const tkm = 1 / (isKm ? kpm : (kpm * 0.621371))
        return ConvertS2M(tkm * 60) + ` / ${isKm ? 'km' : 'mile'}`
    }

    const getGarminSpeed = (isKm: boolean, speed: number) => {
        const kph = (isKm ? speed : (speed * 0.621371)) * (3600 / 1000)
        return kph.toFixed(2) + ` ${isKm ? ' kph' : 'mph'}`
    }

    const getMarathonMiles = (marathonType: string) => {
        if (marathonType.toUpperCase().includes('HALF')) return HALF_MARATHON
        if (marathonType.toUpperCase().includes('FULL')) return MARATHON
        if (marathonType.toUpperCase().includes('10K')) return TENK
        if (marathonType.toUpperCase().includes('8K')) return EIGHTK
        if (marathonType.toUpperCase().includes('5K')) return FIVEK

        return MARATHON
    }

    return {
        isLateStart,
        getWeekDays,
        ConvertH2M,
        ConvertM2Mt,
        CalcvDOT,
        DaysBetween,
        DaysBetween2,
        ComputeAgg,
        getCurrentWeek,
        getThisDate,
        getCurrentDay,
        ConvertM2H2,
        ConvertM2H,
        ConvertT2MS,
        ConvertS2M,
        computeKmPace,
        CalcBPace,
        CalcRPace,
        CalcIPace,
        CalcTPace,
        CalcMPace,
        CalcMiscPace,
        CalcEPace,
        CalcLPace,
        getRealStart,
        GetWeek,
        GetWeek2,
        getCurrentVDOT,
        ConvertFullToHalf,
        ConvertFullTo5k,
        ConvertFullto10k,
        ConvertS2TimeObject,
        convertDistance,
        isWeekend,
        DaysInMonth,
        DaysBetweenCalendar,
        convertDateToUTC,
        ConvertMetersToMiles,
        getGarminPace,
        getGarminSpeed,
        getMarathonMiles,
        ConvertSecondsToObject,
        ConvertFullto8k
    }
}

export default useTrainingPlanUtils

