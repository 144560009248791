/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid } from "@mui/material"
import { FC } from "react"
import useTrainingPlanUtils from "../../hooks/useTrainingPlanUtils";

interface AppleWatchWorkoutLapsProps {
    data: any[],
    userInfo: any
}

const AppleWatchWorkoutLaps: FC<AppleWatchWorkoutLapsProps> = ({ data, userInfo }) => {

    const { ConvertMetersToMiles, ConvertS2M, getGarminPace } = useTrainingPlanUtils();

    const renderWorkoutLaps = () => {
        const running = []
        const otherActivities = []
        for (const act of data) {
            const isRunning = act.activityType === 'RUNNING'
            const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
            const table = act.laps?.length > 0 && <>
                {/* <Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.activityName}</Grid> */}
                <Grid container xs={12} sx={{ p: '0px 10px', backgroundColor: 'white', borderRadius: '4px' }} justifyContent='flex-start' alignItems="center">
                    <Grid container xs={12} sx={{ p: '10px 5px 10px 5px' }} justifyContent='flex-start' alignItems="center">
                        <Grid container xs={1} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>Lap</Grid>
                        <Grid container xs={3} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>Time</Grid>
                        <Grid container xs={3} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins', fontSize: '14px', position: 'relative' }}>
                            Distance<Box sx={{ position: 'absolute', fontFamily: 'Poppins', fontSize: '10px', right: 0, bottom: -10 }} >{paceUnit}</Box>
                        </Grid>
                        <Grid container xs={5} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins', fontSize: '14px', position: 'relative' }}>
                            Avg Pace<Box sx={{ position: 'absolute', fontFamily: 'Poppins', fontSize: '10px', right: 0, bottom: -10 }} >min/{paceUnit}</Box>
                        </Grid>
                    </Grid>

                    {act.laps.map((lap: any, i: number) => {
                        const lapDistance = lap.totalDistanceInMeters
                        const lD = userInfo?.km == 'Y' ? (lapDistance / 1000)?.toFixed(2) : ConvertMetersToMiles(lapDistance)?.toFixed(2)

                        const lapUnit = userInfo?.km == 'Y' ? 'km' : 'mile'
                        return lap ? (
                            <Grid key={i} container xs={12} sx={{ p: '5px' }}>
                                <Grid container xs={1} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
                                    {i + 1}
                                </Grid>
                                <Grid container xs={3} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
                                    {ConvertS2M(lap.timerDurationInSeconds)}
                                </Grid>
                                <Grid container xs={3} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
                                    {lD}
                                </Grid>
                                <Grid container xs={5} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px' }}>
                                    {ConvertS2M(lap.timerDurationInSeconds)}
                                </Grid>
                            </Grid>
                        ) : ''
                    })}
                </Grid>
            </>

            table && (isRunning ? running.push(table) : otherActivities.push(table))
        }

        return <Box sx={{ width: '100%', fontFamily: 'Poppins', position: 'relative', mt: '10px' }}>
            <Grid container xs={12} justifyContent='flex-start' alignItems="center">
                {[...running, ...otherActivities]}
            </Grid>
        </Box>
    }

    return renderWorkoutLaps()
}

export default AppleWatchWorkoutLaps