import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import useSWR from 'swr'
import is_numeric from 'locutus/php/var/is_numeric'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import { UserInfoProps } from '../../modules/types'
import { MISC_LOGOS } from '../../modules/miscImages'
import { titleCase, getColorCode, hexToRGB } from '../../modules/miscUtils'
import useAppStorage from '../../hooks/useAppStorage'
import useHomePage from '../../hooks/useHomePage'
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import RSFlexCard from '../../components/cards/RSFlexCard'
import RSCardCont from '../../components/cards/RSCardCont'

export type RunningPlanProps = UserInfoProps & {
	hasLoaded: boolean | undefined
}

const RunningPlan: FC<RunningPlanProps> = ({ userInfo, hasLoaded }) => {
	const isMobile = useIsMobileScreen();
	const { data: accountProfile } = useSWR(userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })
	const navigate = useNavigate()
	const [userInfoNew, setUserInfo] = useState<any>(null)
	const [data, setData] = useState<any>({})
	const [logoLoading, setLogoLoading] = useState(true)
	const { isIos } = useAppStorage()
	const { infoRow } = useHomePage({ isIos: isIos || false, userInfo: userInfoNew || userInfo })

	const onErrorLoadImg = () => {
		return (e: any) => {
			e.target.src = data.logo
			e.target.style = 'scale: 1.3'
		}

	}


	useEffect(() => {
		const userAccountData = accountProfile?.data?.result?.[0];
		if (userAccountData?.user_id) {
			const newUserInfo = { ...userInfo, km: userAccountData?.measurement_system === "imperial" ? "N" : "Y" }
			setUserInfo(newUserInfo)
		}
	}, [accountProfile])


	useEffect(() => {
		if (infoRow?.length) {
			const infoData = infoRow[0]
			const { id, link, text, daysUntilRace, showDaysUntilRace, trainingType, scheduleText, planType } = infoData
			let redirectLink = id && link && scheduleText ? `${link?.replace('/#', '')}?planId=${id}` : '/explore'
			if (planType === 'GPT') {
				redirectLink = redirectLink.replace('training-plan', 'training-plan-v3')
			}

			const newText = text?.split('\n')
			const trainingTypeFormatted = !trainingType
				? '' : trainingType[trainingType.length - 1] == 'k'
					? trainingType.toUpperCase()
					: titleCase(trainingType)
			const trainingText = trainingType ? `${trainingTypeFormatted} ${trainingType.toLowerCase().includes('recovery') ? '' : ' training'}` : ''
			const daysUntilRaceText = trainingType?.toLowerCase().includes('recovery') ? `${daysUntilRace} days remaining` : showDaysUntilRace
				? `${daysUntilRace} days until race day`
				: ''
			setData({ ...infoData, trainingText, daysUntilRaceText, link: redirectLink, text: newText })
		}
	}, [infoRow])

	const renderBody = () => {
		const color = getColorCode(data.scheduleText)
		const sideColor = '#004AAD'
		const bgColor = hexToRGB(sideColor, 0.1)
		const activityArr = data.scheduleText?.split('|')?.map((v: any) => v.trim())
		const mileArr = activityArr?.[0]?.split(' ')
		const isNumber = mileArr ? is_numeric(mileArr[0]) : false
		const paceArr = data?.pace?.split(' ')
		const m = paceArr?.find((v: any) => v.includes('m'))
		const s = paceArr?.find((v: any) => v.includes('s'))
		const crossTrainActivities = activityArr?.[2]?.split(',')
		let act = activityArr?.[1] || activityArr?.[0]
		act = act?.toUpperCase()
		act = isMobile ? (act === "PROGRESSION" ? "PROG." : (act === "RECOVERY" ? 'RECOV.' : act)) : act
		const isRest = act === 'REST'
		const isCT = act === 'CROSS TRAIN'
		const isResult = act?.includes('RESULT')
		const watchRecovery = act?.includes('RECOVERY VIDEOS')
		const isAssessment = act?.includes('ASSESSMENT')
		const isTrainingNotStarted = moment(data.trainingStartDate).isAfter(moment(new Date()))
		const moreDetails = !isTrainingNotStarted && (data.paceDetails || isCT || isResult || watchRecovery || isAssessment) ? true : false
		const hasMiles = !isCT && !isResult && !watchRecovery && !isAssessment
		const pPercent = data?.progressPercent || 0
		return <RSCardCont onClick={() => navigate(data?.link || '/#/explore')} isLoading={!hasLoaded || data?.scheduleText === undefined ? true : false} sx={{ maxWidth: '450px', width: '100%', p: 0, justifyContent: 'flex-start', flexDirection: 'column', minHeight: '80px' }}>
			{data.scheduleText ? <>
				<Box sx={{ backgroundColor: bgColor, position: 'relative', height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Grid container xs={3} sx={{ backgroundColor: sideColor, position: 'relative', height: '100%', zIndex: 1 }}>
						<Box sx={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							{data?.race_logo ?
								<Grid container sx={{ position: 'absolute', height: '65px', width: '65px', textAlign: 'center', fontFamily: 'Poppins-SemiBold !important', fontSize: '25px !important', color: '#742F4E', overflow: 'hidden'}} justifyContent='center' alignItems='flex-start'>
									{logoLoading && <Box className='loading-days toRight' sx={{ border: 'none !important' }} /> }
									<img onLoad={()=> setLogoLoading(false)} src={data.race_logo} style={{ borderRadius: '10px', height: '100%', width: '100%' }} onError={onErrorLoadImg()}/>
								</Grid>
								: <img src={data.logo} style={{ scale: '1.3', margin: 'auto', marginRight: '-3px' }} />
							}
						</Box>
					</Grid>
					<Grid container xs={9} sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
						<Box sx={{ background: sideColor, width: '35px', height: '120px', position: 'absolute', transform: 'rotate(16deg)', left: -17, top: -12 }} />
						<Grid container xs={12} sx={{ pl: '20px', pr: '15px', flexDirection: 'column', py: '10px' }} justifyContent='space-between' alignItems='flex-start'>
							<Box sx={{ pl: '20px', width: '100%' }}>
								<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>{data?.event_name || data.trainingText}</Box>
							</Box>
							<Box sx={{ pl: '10px', display: 'flex', width: '100%', justifyContent: 'flex-start', height: '20px' }}>
								{<Box sx={{ position: 'relative', mr: '2px', height: '5px', borderRadius: '2px', width: '100%', backgroundColor: hexToRGB('#010101', 0.3) }}>
									<Box sx={{ position: 'absolute', top: 0, left: 0, backgroundColor: '#010101', height: '100%', width: `${pPercent}%` }} />
									<i className="fa-solid fa-flag-checkered" style={{ position: 'absolute', top: -20, right: -12, fontSize: '16px' }} />
								</Box>}
							</Box>
						</Grid>
					</Grid>
					<Box className='home-percent-cont'>
						{pPercent}% Complete
					</Box>
				</Box>

				{act && <Box sx={{ height: isRest ? '50px' : isTrainingNotStarted ? 'auto' : '110px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', p: '5px 10px', flexDirection: 'column' }}>
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: '10px' }}>
						{moment(moment().format('YYYY/MM/DD')).isBefore(moment(data.trainingStartDate)) ? (
							<>
								<Box sx={{ lineHeight: '1.2em', ml: '6px', fontSize: '16px', fontFamily: 'Poppins', pr: '5px' }}>{data.text}</Box>
							</>) : (
							<>
								<Box sx={{ lineHeight: '1.2em', ml: '6px', fontSize: '16px', fontFamily: 'Poppins', pr: '5px' }}>Today:</Box>
								<Box sx={{ lineHeight: '1.2em', fontSize: '16px', fontFamily: 'Poppins', textAlign: 'left', pr: '20px' }}>{moment().format('MMMM DD')}</Box>
								{!isRest && <Box sx={{ lineHeight: '1.2em', mr: '3px', height: '12px', width: '12px', borderRadius: '50%', backgroundColor: color }} />}
								<Box sx={{ lineHeight: '1.2em', fontSize: '16px', fontFamily: 'Poppins', textAlign: 'left', pl: '5px' }}>{act}</Box>
								{crossTrainActivities?.map((cta: any, i: number) => {
									const isStrength = cta?.toLowerCase()?.includes('strength')
									const isYoga = cta?.toLowerCase()?.includes('yoga')
									const isStretch = cta?.toLowerCase()?.includes('stretch')
									const isRoll = cta?.toLowerCase()?.includes('roll')

									return <Box key={i} sx={{ ml: '5px', width: '20px', height: '20px' }}>
										<Box sx={{ width: '100%', height: '100%', borderRadius: '50%', backgroundColor: '#555', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<i style={{ marginTop: '0.1rem', fontSize: '0.8rem', color: '#ffffff' }}
												className={`fa-solid ${isRoll && 'fa-circle-dashed'} ${isStretch && 'fa-child-reaching'} ${isYoga && 'fa-spa'} ${isStrength && 'fa-dumbbell'}`} />
										</Box>
									</Box>
								})}
							</>
						)}
					</Box>
					{!data.text[0].includes('begins') ? (
						!isRest && <Box sx={{ display: 'flex', justifyContent: 'center', height: isTrainingNotStarted ? 'auto' : '50px' }}>
							{hasMiles && <Box sx={{ px: '15px' }}>
								<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}>{(isNumber ? Number(mileArr[0]).toFixed(1) : mileArr.join(' '))}</Box>
								{mileArr?.length > 1 && isNumber && <Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>{titleCase(mileArr[1])}</Box>}
							</Box>}
							{moreDetails ? <Grid container sx={{ px: '15px', margin: hasMiles ? 0 : 'auto' }}>
								<Box sx={{ fontSize: '18px', fontFamily: 'Poppins' }}>{isAssessment ? 'Begin Assessment' : (isResult ? 'Enter Result' : (watchRecovery ? 'Watch Videos' : `View ${isCT ? 'More' : 'Pace'} Details`))}</Box>
								<ArrowForward sx={{ mt: '10px', ml: '10px', stroke: 'white', strokeWidth: '1.3px', fontSize: '12px' }} />
							</Grid>
								: ((m || s) ? <Box sx={{ px: '15px' }}>
									<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}>{m ? m.replace('m', ':') : ''}{s ? s.replace('s', '').padStart(2, '0') : ''}</Box>
									<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Pace</Box>
								</Box> : <></>)}
						</Box>
					) : <></>}
				</Box>
				}
			</> : <Box sx={{ display: 'flex', minHeight: '80px' }}>
				<img src={MISC_LOGOS.training} style={{ width: '60px', margin: '0px 24px 0px 16px' }} />
				<Grid container sx={{ flexDirection: 'column' }} justifyContent='center' alignItems='flex-start'>
					{(data?.text?.length ? data.text.map((text: string, ti: number) => <Box key={'home-text-' + ti} sx={{ lineHeight: '1.2em', textAlign: 'left', fontFamily: ti ? 'Poppins-Light' : 'Poppins-Medium' }}>{text.trim()}</Box>) : <></>)}
				</Grid>
			</Box>}
		</RSCardCont >
	}

	return <Box>
		<Grid container xs={12} sx={{ fontFamily: 'Poppins-Bold', fontSize: '20px' }}>
			Active Plans
		</Grid>
		<Grid container xs={12} >
			<RSFlexCard videoList={[renderBody()]} sx={{ py: 0 }} />
		</Grid>
	</Box>
}

export default RunningPlan;
