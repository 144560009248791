import {FC} from 'react'
import moment from 'moment';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import RSTheme from '../styles/RSTheme'

export type RSDateInputProps = {
	date: string,
	setDate: Function,
	maxDate?: any,
	minDate?: any,
	label?: string,
	outlineSX?: object
	day?: boolean
	month?: boolean
}

const RSDateInput: FC<RSDateInputProps> = ({date = '', setDate, maxDate, minDate, label = '', outlineSX = {}, day, month}) => {

	const initialDisplay = day ? 'day' : month ? 'month' :'year'
	return (
		<RSTheme>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoItem>
					<DatePicker
						sx={{
							'& .MuiOutlinedInput-root': {
								height: '45px',
							},
							'& .MuiInputBase-input': {
								mt: '4px',
								zIndex: 1,
								fontSize: '14px',
								fontFamily: 'Poppins'
							},
							'& .MuiOutlinedInput-notchedOutline': {
								backgroundColor: 'white',
								...outlineSX
							},
							width: '100%',
						}}
						slotProps={{
							textField: {
								error: false
							}
						}}
						label={label}
						openTo={initialDisplay}
						maxDate={maxDate ? dayjs(moment(new Date(maxDate)).format('YYYY-MM-DD')) : null}
						minDate={minDate ? dayjs(moment(new Date(minDate)).format('YYYY-MM-DD')) : null}
						views={['year','month','day']}
						value={dayjs(moment(new Date(date)).format('YYYY/MM/DD'))}
						onChange={(newValue: any) => setDate(moment(new Date(newValue)).format('YYYY/MM/DD'))}

					/>
				</DemoItem>
			</LocalizationProvider>
		</RSTheme>
	)
}

export default RSDateInput;
