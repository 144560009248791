import { FC } from 'react'

export type RoadProps = {
	height?: number | string,
	width?: number | string,
}

const Road: FC<RoadProps> = ({height = '100%', width = '100&'}) => {
	const isHNum = typeof height === 'number' ? 'px' : ''
	const isWNum = typeof width === 'number' ? 'px' : ''
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height={`${height}${isHNum}`} width={`${width}${isWNum}`}>
			<path d="M256 32L128 32 0 480l256 0 0-64 0-32 64 0 0 32 0 64 256 0L448 32 320 32l0 64 0 32-64 0 0-32 0-64zm64 192l0 64 0 32-64 0 0-32 0-64 0-32 64 0 0 32z"/>
		</svg>
	)
}

export default Road
