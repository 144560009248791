import { FC } from 'react'

export type MountainProps = {
	height?: number | string,
	width?: number | string,
}

const Mountain: FC<MountainProps> = ({height = '100%', width = '100&'}) => {
	const isHNum = typeof height === 'number' ? 'px' : ''
	const isWNum = typeof width === 'number' ? 'px' : ''
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={`${height}${isHNum}`} width={`${width}${isWNum}`}>
			<path className="fa-secondary" opacity=".4" d="M143.1 201.4c21.6 28.9 43.3 57.7 64.9 86.6c16-21.3 32-42.7 48-64c42.7 0 85.3 0 128 0C341.3 160 298.7 96 256 32c-37.6 56.5-75.3 112.9-112.9 169.4z"/><path className="fa-primary" d="M384 224l-128 0-48 64-64.9-86.6L0 416l0 64 512 0 0-64L384 224z"/>
		</svg>
	)
}

export default Mountain
