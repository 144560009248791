import { TrainingPlanChangeLog } from '../api/v2/training-plan/training-plan.types'
import { AdjustVdotParams, ICreateTrainingPlanParams, MarathonValRecord, UserCustomPaceRecord, UserNotesRecord, UserOverrideRecord, UserPdfRecord, UsersRestCommentsRecord, UserRaceResults, SkipMaintenancePlan } from '../api/v2/types'
import { getReq, postReq, putReq } from './apiConsume'

export const GetPlanInfo = async (planId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/info/${planId}`, { signal: signal })

// Get Userinfo
export const GetUserInfo = async (uid: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/userinfo?uid=${uid}`, { signal: signal })
// Udpate User Location
export const UpdateUserLocation = async (userId: number, location: string) =>
    await putReq(`/v2/training-plan/userinfo/location`, { userId, location })

// Create Training Plan
export const CreateTrainingPlan = async (args: ICreateTrainingPlanParams) =>
    await postReq('/v2/training-plan/create', args)

// Update Training Plan
export const UpdateTrainingPlan = async (args: ICreateTrainingPlanParams) =>
    await putReq('/v2/training-plan/update', args)

// Update Training Plan Level
export const UpdateTrainingPlanLevel = async (userId: number, level: number, internal_id: number) =>
    await putReq('/v2/training-plan/userinfo/level', { userId, level, internal_id })

// Check Active Training Plan
export const CheckActivePlan = async (id: number) =>
    await getReq(`/v2/training-plan/has-active-plan?id=${id}`)

// Get Marathon Values
export const GetMarathonValues = async (params: MarathonValRecord, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/marathon?marathon=${params.marathon}&type=${params.type}&training_goal=${params.training_goal}&week=${params.week}&distance=${params.distance}`, { signal: signal })

export const GetAllMarathonValues = async (params: MarathonValRecord) =>
    await getReq(`/v2/training-plan/all-marathon?marathon=${params.marathon}&type=${params.type}&training_goal=${params.training_goal}&distance=${params.distance}`)

export const GetMarathonGoals = async (goalWeek: number, distance: number, marathon: number, week: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/marathon/race-goals?goalWeek=${goalWeek}&distance=${distance}&week=${week}&marathon=${marathon}`, { signal: signal })

// Get Marathon Paces
export const GetMarathonPace = async (marId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/marathon-pace?marId=${marId}`, { signal: signal })

// Get Workouts
export const GetWorkouts = async (marId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/workouts?marId=${marId}`, { signal: signal })

// user custom paces
export const GetUserCustomPace = async (userId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/user-custom-pace?userId=${userId}`, { signal: signal })

export const CreateUserCustomPace = async (params: UserCustomPaceRecord) =>
    await postReq(`/v2/training-plan/user-custom-pace`, params)

export const UpdateUserCustomPace = async (params: UserCustomPaceRecord) =>
    await putReq(`/v2/training-plan/user-custom-pace`, params)

// user notes
export const GetUserNotes = async (userId: number, marId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/user-notes?userId=${userId}&marId=${marId}`, { signal: signal })

export const GetAllUserNotes = async (userId: number, signal?: AbortSignal) =>
    await getReq(`/v2/training-plan/all-user-notes?userId=${userId}`, { signal: signal })

export const CreateUserNotes = async (args: UserNotesRecord) =>
    await postReq('/v2/training-plan/user-notes', args)

export const UpdateUserNotes = async (args: UserNotesRecord) =>
    await putReq('/v2/training-plan/user-notes', args)

//user rest comments
export const GetUserRestComments = async (userId: number, signal?: AbortSignal) =>
    await getReq(`/v2/training-plan/user-rest-comments?userId=${userId}`, { signal: signal })

export const CreateUserRestComments = async (args: UsersRestCommentsRecord) =>
    await postReq('/v2/training-plan/user-rest-comments', args)

export const UpdateUserRestComments = async (args: UsersRestCommentsRecord) =>
    await putReq('/v2/training-plan/user-rest-comments', args)

// create user override
export const CreateUserOverride = async (args: Array<UserOverrideRecord>) =>
    await postReq('/v2/training-plan/user-override', args)

export const GetUserOverride = async (userId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/user-override?userId=${userId}`, { signal: signal })

//save vdot adjustments
export const SaveVdotAdjustments = async (args: AdjustVdotParams) =>
    await postReq(`/v2/training-plan/user-vdot-adjust`, args)

// Get User vdot adjustments
export const GetUserVdotChanges = async (uid: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/user-vdot-adjust?uid=${uid}`, { signal: signal })

// Save User PDF
export const SaveUserPDF = async (args: UserPdfRecord) =>
    await postReq(`/v2/training-plan/save-userpdf`, args)

// Upload User PDF
export const UploadPDF = async (data: Blob) =>
    await postReq(`/v2/training-plan/upload`, { data })

export const GetCurrentUserPdf = async (userId: number, start: any, race_date: any, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/current-user-pdf?userId=${userId}&start=${start}&race_date=${race_date}`, { signal: signal })

export const GetOldUserPDF = async (userId: number, fileId: number, signal?: AbortSignal) =>
    await getReq(`/v2/training-plan/pdf-file?userId=${userId}&fileId=${fileId}`, { signal: signal })

export const GetAllUserPdf = async (userId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/all-user-pdf?userId=${userId}`, { signal: signal })

export const GetUserPdfData = async (userId: number, fileId: number, signal?: AbortSignal) =>
    await getReq(`/v2/training-plan/pdf-data?userId=${userId}&fileId=${fileId}`, { signal: signal })

export const CreatePdf = async (userInfo: any, trainingData: Array<any>, insertId: number, pdfData: any, currentUser: any, userRestComments: Array<any>, vdotAdjustments: Array<any>, customPaces: Array<any>) =>
    await postReq(`/v2/training-plan/save-pdf`, { userInfo, insertId, trainingData, pdfData, currentUser, userRestComments, vdotAdjustments, customPaces })

//Journey
export const GetJourney = async (userId: number, signal: AbortSignal) =>
    await getReq(`/v2/training-plan/userinfo?uid=${userId}`, { signal: signal })

//Race Result
export const GetRaceResult = async (userId: number, tpId: number, signal: AbortSignal, type?: number) =>
    await getReq(`/v2/race-results?user_id=${userId}&training_plan_id=${tpId}&type=${type}`, { signal: signal })

export const CreateRaceResult = async (args: UserRaceResults) =>
    await postReq(`/v2/race-results`, args)

export const UpdateRaceResult = async (args: UserRaceResults, signal: AbortSignal) =>
    await putReq(`/v2/race-results`, args, { signal: signal })

//Deactivate Plan
export const DeactivatePlan = async (userId: number, signal: AbortSignal) =>
    await putReq(`/v2/training-plan/deactivate/${userId}`, { signal: signal })

//Check if day is synched to garmin
export const getGarminSyncData = async (planId: number, workoutId: number, signal: AbortSignal) =>
    await getReq(`/v2/garmin/isWorkoutSynched?plan_id=${planId}&workout_id=${workoutId}`, { signal: signal })

// Remove scheduled workout
export const removeScheduledWorkout = async (body: any) =>
    await postReq(`/v2/garmin/workouts/delete`, body)

//Skip Maintenance
export const SkipMaintenance = async (args: SkipMaintenancePlan) =>
    await putReq(`/v2/recovery-plan/skip-maintenance`, args)

//Update Event Name
export const UpdateEventName = async (plan_id: number, eventName: string) =>
    await putReq(`/v2/gpt-training-plan-info/update-event-name`, { plan_id, eventName })

// Create Change Log
export const CreateChangeLog = async (args: TrainingPlanChangeLog) =>
    await postReq(`/v2/training-plan/change-logs`, args)

// Create Change Log Edit Dailies
export const createChangeLogEditDailies = async (args: TrainingPlanChangeLog) =>
    await postReq(`/v2/training-plan/change-logs/edit-dailies`, args)


export const getChangeLogEditDailies = async (planId: number) =>
    await getReq(`/v2/training-plan/change-logs/edit-dailies?plan_id=${planId}`)