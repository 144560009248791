/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from "moment";
import useTrainingPlanUtils from "./useTrainingPlanUtils";

// Define types for workout data structure
interface Metadata {
    HKTimeZone: string;
    HKIndoorWorkout: number;
    HKAverageMETs: number | null;
    HKElevationAscended: number | null;
}

interface WorkoutEvent {
    startDate: string;
    endDate: string;
    eventType: string;
    eventTypeInt: number;
}

interface AnchoredWorkout {
    id: string;
    device: string;
    distance: number;
    activityName: string;
    sourceId: string;
    sourceName: string;
    tracked: boolean;
    duration: number;
    start: string;
    end: string;
    activityId: number;
    calories: number;
    metadata: Metadata;
    workoutEvents: WorkoutEvent[];
}

export interface WorkoutData {
    id: string;
    start: string;
    end: string;
    activityId: number;
    calories: number;
    activityName: string;
    distance: number;
    durationInSeconds: number;
    durationInMinutes: number;
    anchoredWorkouts: AnchoredWorkout[];
    workoutRoutes?: any;
    workoutRoutesError?: any
}

// Define the lap/split result type
type Lap = {
    start: string;
    end: string;
    duration: number; // Duration in seconds
    distance: number; // Distance in miles
    pauseDuration?: number; // Duration in seconds
};

interface GroupedWorkoutEvent {
    startDate: string; // ISO 8601 date string
    endDate: string; // ISO 8601 date string
    events: WorkoutEvent[];
}

type Unit = 'mile' | 'km';

const useHealthActivity = () => {

    function removeOverlappingEventsv2(events: any) {
        // Parse dates and sort events by startDate
        events.forEach((event: any) => {
            event.startDate = new Date(event.startDate);
            event.endDate = new Date(event.endDate);
        });

        events.sort((a: any, b: any) => a.startDate - b.startDate);

        const filteredEvents = [events[0]]; // Initialize with the first event

        for (let i = 1; i < events.length; i++) {
            const lastEvent = filteredEvents[filteredEvents.length - 1];
            const currentEvent = events[i];

            if (currentEvent.startDate <= lastEvent.endDate) {
                // Overlap detected: adjust the last event's endDate if needed
                lastEvent.endDate = new Date(Math.max(lastEvent.endDate, currentEvent.endDate));
            } else {
                // No overlap: add the current event to the filtered events
                filteredEvents.push(currentEvent);
            }
        }

        // Convert dates back to string format
        filteredEvents.forEach(event => {
            event.startDate = event.startDate.toISOString();
            event.endDate = event.endDate.toISOString();
        });

        return filteredEvents;
    }

    function removeOverlappingEvents(events: any) {
        const parsedEvents = events.map((event: any) => ({
            ...event,
            startDate: moment(event.startDate).toDate(),
            endDate: moment(event.endDate).toDate()
        }));

        parsedEvents.sort((a: any, b: any) => a.startDate - b.startDate);

        for (let i = 0; i < parsedEvents.length - 1; i++) {
            const current = parsedEvents[i];
            const next = parsedEvents[i + 1];

            if (next.startDate < current.endDate) {
                current.endDate = next.startDate;
            }
        }

        return parsedEvents.map((event: any) => ({
            ...event,
            startDate: event.startDate.toISOString(),
            endDate: event.endDate.toISOString()
        }));
    }

    function processData(data: string[]): string[] {
        const numbers: string[] = data.map(String);
        const uniqueWholeNumbers: string[] = Array.from(new Set(numbers.map(num => String(Math.floor(Number(num))))));
        const lastValue: string = numbers[numbers.length - 1];
        return [...uniqueWholeNumbers, lastValue];
    }


    function getWorkoutLaps(workoutData: WorkoutData): Lap[] {
        const totalDistance = workoutData.distance;
        const totalDuration = workoutData.durationInSeconds;
        let workoutEvents = workoutData.anchoredWorkouts.find(x => x.id == workoutData.id)?.workoutEvents || [];
        let pauseAndResumeEvents: any[] = [];
        const laps: Lap[] = [];
        const lapSummary: any = []
        const resultsPerDistance: any = []
        const finalLaps: any = []
        const eventLaps: any = []
        let curDistance = 0
        let distances: string[] = []

        if (!workoutEvents) return []

        // workoutEvents = removeOverlappingEvents(workoutEvents)
        // console.log(workoutEvents)
        pauseAndResumeEvents = workoutEvents.filter((event: any) => event.eventType === "pause" || event.eventType === "resume");
        //get duration of pause and resume events
        pauseAndResumeEvents.forEach((event: any, index: number) => {
            if (event.eventType === "pause") {
                const startEvent = event
                const endEvent = pauseAndResumeEvents[index + 1];
                if (endEvent) {
                    const startDate = new Date(startEvent.startDate);
                    const endDate = new Date(endEvent.startDate);
                    const durationInSeconds = moment(endDate).diff(moment(startDate), 'milliseconds') / 1000
                    // console.log(durationInSeconds)
                    pauseAndResumeEvents[index].duration = durationInSeconds || 0
                    pauseAndResumeEvents[index].endDate = endEvent.startDate
                }
            }
        })
        workoutEvents = removeOverlappingEvents(workoutEvents.filter((event: any) => event.eventType === "segment" || event.eventTypeInt == 4));
        // console.log(workoutEvents)

        workoutEvents.forEach((event) => {
            const startDate = new Date(event.startDate);
            const endDate = new Date(event.endDate);
            let pauseDuration = 0;

            const pauseEvents = pauseAndResumeEvents.filter((pauseEvent: any) => new Date(pauseEvent.startDate) > startDate && new Date(pauseEvent.startDate) < endDate)

            if (pauseEvents.length > 0) {
                pauseDuration = pauseEvents.filter((pauseEvent: any) => pauseEvent.eventType === "pause").reduce((acc: number, pauseEvent: any) => acc + pauseEvent.duration, 0) || 0
            }

            const durationInSeconds = moment(endDate).diff(moment(startDate), 'milliseconds') / 1000;
            const distance = Number(Number((durationInSeconds / totalDuration) * totalDistance).toFixed(2));
            laps.push({
                start: event.startDate,
                end: event.endDate,
                duration: durationInSeconds,
                distance,
                pauseDuration
            });
        });

        // console.log(laps)
        laps.forEach((lap, index) => {
            curDistance += lap.distance
            curDistance = Number(Number(curDistance).toPrecision(2))
            lapSummary.push({ ...lap, curDistance })
            distances.push(curDistance.toPrecision(3))
        });
        // const uniqueValues: any = new Set(distances)

        // distances = [...uniqueValues].filter(value => Number(value) > 0);
        distances = processData(distances).filter(value => Number(value) > 0);

        distances.forEach((distance) => {
            const lapsPerDistance = lapSummary.filter((lap: any) => lap.curDistance <= distance)
            resultsPerDistance.push({ distance, lapsPerDistance })
            lapSummary.splice(0, lapsPerDistance.length)
        })

        // console.log(resultsPerDistance)

        resultsPerDistance.forEach((result: any) => {

            let pauseDuration = 0
            result.lapsPerDistance.forEach((lap: any, index: number) => {
                if (lap.pauseDuration) {
                    pauseDuration += lap.pauseDuration
                }
            })

            const distance = result.lapsPerDistance.reduce((acc: number, lap: any) => acc + lap.distance, 0)
            const duration = (moment(result.lapsPerDistance[result.lapsPerDistance.length - 1]?.end).diff(moment(result.lapsPerDistance[0]?.start), 'milliseconds') / 1000) - pauseDuration

            finalLaps.push({
                start: result.lapsPerDistance[0]?.start,
                end: result.lapsPerDistance[result.lapsPerDistance.length - 1]?.end,
                totalDistanceInMeters: Number(Number(distance || 0).toFixed(1)) * 1609.34,
                timerDurationInSeconds: duration,
            })
        })

        eventLaps.push({
            activityId: workoutData.id,
            laps: finalLaps,
            activityName: workoutData.activityName,
            type: "apple_health",
            activityType: workoutData.activityName.toUpperCase(),
        })

        return eventLaps;
    }


    return { getWorkoutLaps }
}

export default useHealthActivity