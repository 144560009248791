/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import RSCloseBtn from '../buttons/RSCloseBtn'
import useQueryParams from '../../hooks/useQueryParams';
import { isMobile, isSafari } from 'react-device-detect'
import DownloadAppButtons from '../../pages/home/DownloadAppButtons';
import { useLocation } from 'react-router-dom';
import useScrollDirection from '../../hooks/useScrollDirection';
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
			contrastText: '#010101'
		},
	},
});

export type RSHeaderContProps = {
	className?: string,
	show?: boolean,
	children?: any,
	sx?: object,
	barSX?: object,
	contSX?: object,
	secondary?: boolean,
	isInside?: boolean,
	drawer?: boolean,
	noShadow?: boolean,
	scrollHide?: boolean,
	bottom?: boolean,
	title?: string,
	backClick?: Function,
	closeClick?: Function,
	isStatic?: boolean,
	onboarding?: boolean,
	header?: boolean,
	noBanner?: boolean,
	childFullWidth?: boolean
}

const HideOnScroll: FC<RSHeaderContProps> = ({ children, drawer, scrollHide }) => {
	const drawerElement = document?.getElementsByClassName('MuiDrawer-paperAnchorRight')?.[0]
	const trigger = useScrollTrigger({
		target: drawer ? drawerElement : window
	});

	return (scrollHide ? <Slide appear={false} direction="down" in={!trigger}>
		{children}
	</Slide> : <>{children}</>);
}

const RSHeaderCont: FC<RSHeaderContProps> = ({ className = '', show = true, children, drawer, sx = {}, barSX = {}, contSX = {}, secondary, isInside, noShadow, scrollHide, bottom, title, backClick, isStatic, onboarding, header, closeClick, noBanner, childFullWidth }) => {
	const { requestKey: deviceKey } = useQueryParams()
	const requestKey = localStorage.getItem('deviceRequestKey') || deviceKey
	const location = useLocation()
	const isShow = location.pathname === '/renew' || location.pathname === '/suggested-programs' || location.pathname === '/signup' || location.pathname === '/login' || location.pathname === '/explore' ? false : true
	const [showDownloadBanner, setShowDownloadBanner] = useState(!requestKey && isMobile && !isSafari && isShow)
	const isMobileSC = useIsMobileScreen();
	const scrollDirection = useScrollDirection()

	const style = {
		height: '60px',
		position: 'relative',
		width: '100%',
		zIndex: 1001,
		mt: showDownloadBanner && !onboarding && !bottom && !noBanner ? '50px' : 0,
		...sx
	}

	const barStyle = {
		px: 2,
		height: 'inherit',
		width: '100%',
		top: bottom ? 'auto' : (secondary ? 60 : 0),
		bottom: bottom ? 0 : 'auto',
		right: isInside ? 'auto' : 0,
		mt: showDownloadBanner && !onboarding ? '50px' : 0,
		transition: 'margin-top 0.2s',
		...barSX
	}

	if (!onboarding && scrollDirection && scrollDirection === "down") {
		barStyle.mt = 0
	}

	return show ? <ThemeProvider theme={theme}>
		<Box className={className} sx={style} >
			{showDownloadBanner && !onboarding && !bottom ? <DownloadAppButtons sx={{display: noBanner ? 'none' : 'flex'}}/> : undefined}
			<HideOnScroll drawer={drawer} scrollHide={scrollHide}>
				<AppBar position={isStatic ? 'static' : 'fixed'} className={`${noShadow ? 'no-bs' : 'default-bs'} ${drawer ? 'sm-mw ' : (isInside ? 'default-mw' : '')}`} sx={barStyle}>
					<Grid container xs={12} justifyContent="center" alignItems="center">
						<Box className='default-mw' sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%', ...contSX }}>
							{backClick && <Button sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '42px', height: '100%', ml: -2, color: '#010101', p: 0, minWidth: 0 }} onClick={() => backClick()} >
								<ArrowBack />
							</Button>}
							{title && !closeClick && <Box className='transition-all' sx={{ fontFamily: 'Poppins-Medium', fontSize: '18px', letterSpacing: 'normal' }}>
								{header ? <h4 style={{ fontWeight: 'bold', paddingTop: '7px' }}>{title}</h4> : title}
							</Box>}
							{children && <Box sx={{minWidth: backClick ? '26px' : 0, width: childFullWidth && !title ? 'calc(100% - 26px)' : (backClick ? 'auto' : '100%') }}>{children}</Box>}
							{backClick && title && !children && !closeClick ? <Box sx={{ width: '26px'}} /> : <></>}
							{closeClick && <Box className='pointer' onClick={() => closeClick()} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
								<RSCloseBtn />
								{title && !isMobileSC && <Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									{header ? <h4 style={{ fontWeight: 'bold', paddingTop: '7px' }}>{title}</h4> : title}
								</Box>}
							</Box>}
						</Box>
					</Grid>
				</AppBar>
			</HideOnScroll>
		</Box>
	</ThemeProvider> : <></>
}

export default RSHeaderCont;
