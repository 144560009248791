/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import { deleteReq, postReq, putReq } from '../../modules/apiConsume'
import CryptoJS from 'crypto-js';

interface GPTPrompt {
    version?: number | 1
    race_type?: string | ''
    step1Params?: any
    step2Params?: any
}
const useGPTPrompts = ({ version, race_type, step1Params, step2Params }: GPTPrompt) => {

    const { data, isLoading, mutate: mutatePrompts } = useSWR(`/openai/get-prompts`, { refreshInterval: 0 })

    const [prompts, setPrompts] = useState<any>([])

    const [isSavingOrUpdating, setIsSavingOrUpdating] = useState(false)

    const generatePromptsFromText = (prompt: string, params: any) => {
        return prompt?.replace(/\${(.*?)}/g, (_, expression) => {
            try {
                return new Function('params', `with (params) { return ${expression} }`)(params);
            } catch (e) {
                // console.error(e);
                return ''
            }
        });
    };

    const getTotalSteps = (raceType: string, pr: any) => {
        try {
            const p = pr.data.result?.filter((prompt: OpenAIPrompt) => prompt.race_type === raceType && prompt.status == 1)
            return p?.length
        } catch (e) {
            console.log(e)
            return 0
        }
    }

    const getTrainingPlanPrompts = (step: string, raceType: string, params: any) => {
        try {
            const stepPrompts = prompts?.data?.result?.find((prompt: OpenAIPrompt) => prompt.step === step && prompt.race_type === raceType && prompt.status == 1)
            if (stepPrompts)
                return generatePromptsFromText(stepPrompts?.plan_prompts, params)
            else return '';
        } catch (e) {
            console.log(e)
            return ''
        }
    }

    const checkDupplicatePrompt = (prompts: OpenAIPrompt[], raceType: string, step: string, version: number) => {
        const dupp = prompts?.find((prompt: OpenAIPrompt) => prompt.race_type === raceType && prompt.step === step && prompt.version === version)
        if (dupp) return true
    }

    const updatePromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/update-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const createPromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await postReq(`/v2/openai/create-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const deletePromptVersion = async (id: number) => {
        setIsSavingOrUpdating(true)
        await deleteReq(`/v2/openai/delete-prompt?id=${id}`)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const restorePromptVersion = async (id: number) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/restore-prompt`, { id })
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const activatePromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/activate-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    useEffect(() => {
        const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

        if (data?.data?.result) {
            if (typeof data.data.result === 'string') {
                const bytes = CryptoJS.AES.decrypt(data.data.result, String(CRYPTO_KEY));
                const decryptedData = JSON?.parse(bytes?.toString(CryptoJS.enc.Utf8));
                data.data.result = decryptedData
            }
            setPrompts(data)
        }
    }, [data])


    return { prompts, isLoading, getTrainingPlanPrompts, updatePromptVersion, createPromptVersion, isSavingOrUpdating, mutatePrompts, checkDupplicatePrompt, deletePromptVersion, activatePromptVersion, getTotalSteps, restorePromptVersion }
}

export default useGPTPrompts