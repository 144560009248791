import { FC } from 'react';
import TextField from '@mui/material/TextField';

export type RSInputProps = {
	value: any,
	setValue: any,
	title?: string,
	disabled?: boolean,
	type?: string,
	min?: number,
	max?: number,
	wholeNumber?: boolean,
	placeholder?: string,
	sx?: any
}



const RSInput: FC<RSInputProps> = ({ value, setValue, title = '', disabled = false, type = '', min, max, wholeNumber, placeholder = '', sx = {} }) => {

	return title ? <TextField
		disabled={disabled}
		value={disabled ? '' : value}
		onChange={(e: any) => setValue?.(e.target.value)}
		size="small"
		sx={{
			'& .MuiInputLabel-root': {
				color: disabled ? '#8080802b' : '#80808061',
				fontSize: '14px',
				fontFamily: '#80808061'
			},
			'& .MuiInputLabel-shrink.Mui-focused': {
				color: '#1976d2'
			},
			'& fieldset': {
				borderColor: `${disabled ? 'white' : 'rgba(0, 0, 0, 0.23)'} !important`
			},
			width: '100%',
			height: 'fit-content',
			backgroundColor: 'white',
			mt: '10px',
			...sx
		}}
		label={title}
		variant="outlined"
	/> : <input className='daily-activity-input' style={{ width: '100%', ...sx }} type='number' value={value} placeholder={placeholder}
		onKeyDown={(e: any) => ((e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189 || (wholeNumber && e.keyCode === 180)) && type === 'number') || (wholeNumber && (e.key == '-' || e.key == 'e')) && e.preventDefault()}
		onChange={(e: any) => {
			let val = e.target.value
			if (min !== undefined && val && Number(val) < min)
				val = value
			else if (max !== undefined && val && Number(val) > max)
				val = value
			setValue?.(val)
		}}
	/>
}

export default RSInput;
