/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT, DAYS_OF_WEEK_ABBV_SHORT, DAYS_OF_WEEK_ALT_ABBV_SHORT } from '../training-plan/build/DaysOptions';

import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useRSTabs from '../../hooks/useRSTabs'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';

import RSDateInput from '../../components/input/RSDateInput';
import useGPTTrainingPlan from './useGPTTrainingPlan';

export type EstablishBasePlanProps = {
	accountProfile: AccountProfile,
	allStageDetails: any,
	addDetails: any,
	resetData?: any,
	handleIsStepComplete?: any
	raceDetails: any,
	subStep: number,
	setUpdatedBaseDetails: any
	startDate?: string
}


const EstablishBasePlan: FC<EstablishBasePlanProps> = ({ accountProfile, allStageDetails, addDetails, handleIsStepComplete, raceDetails, subStep, setUpdatedBaseDetails, startDate }) => {
	const { currentWeeklyMileage, lastLongestRun, dob: birthDate } = allStageDetails?.['1-0'] || {}

	const { getMarathonMiles } = useTrainingPlanUtils()

	const { dob, measurement_system, running_activity, gender, run_day } = accountProfile
	const [runningActivity, setRunningActivity] = useState(currentWeeklyMileage || '');
	const [longestRecentRun, setLongestRecentRun] = useState(lastLongestRun || '');
	const [dateValue, setDateValue] = useState<any>(birthDate || dob || null);
	const isMetric = measurement_system === 'metric' ? true : false
	const metricArial = isMetric ? 'Kilometers' : 'Miles'


	const { preferredLongRun, runDaysPerWeek } = allStageDetails?.['1-1'] || {}
	const [longRunDay, setLongRunDay] = useState(preferredLongRun || run_day || '')
	const [daysPerWeek, setDaysPerWeek] = useState(runDaysPerWeek || 3)

	const { availableRunDays } = allStageDetails?.['1-2'] || {}
	const [availableDays, setAvailableDays] = useState<Array<string>>(availableRunDays || [preferredLongRun || run_day])
	const daysOfWeek = run_day === 'Sunday' ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
	const daysOfWeekABBV = run_day === 'Sunday' ? DAYS_OF_WEEK_ALT_ABBV_SHORT : DAYS_OF_WEEK_ABBV_SHORT
	const initialRef = useRef<any>(true)

	const runningAct = running_activity?.toLowerCase()?.includes('more than') ? running_activity?.split(' ')?.[2] : running_activity?.split(' ')?.[0]

	const { calculatePlanWeeks, calculateStartDate, calculateStartWeek, thisSunday } = useGPTTrainingPlan()

	const handleRunningActivityInput = (e: any) => {
		// (/^\d*\.?\d*$/.test(e.target.value)) && e.target.value <= 70 && setRunningActivity(e.target.value)
		console.log(e.target.value)
	}

	useEffect(() => {
		const stageNumber = '1-0'
		const raceDate = (raceDetails?.datePerRaceType && raceDetails?.datePerRaceType.length > 0) ?
			raceDetails?.datePerRaceType?.find((rd: any) => raceDetails.selectedRaceDistance?.toUpperCase()?.includes(rd?.type?.toUpperCase()))?.date : raceDetails?.date || 'invalid date'

		if (`1-${subStep}` === stageNumber) {
			addDetails(stageNumber, {
				planWeeks: calculatePlanWeeks(raceDate, calculateStartWeek(startDate)),
				raceName: raceDetails?.name || 'Marathon',
				raceDate: raceDate,
				startDate: calculateStartWeek(startDate) || calculateStartDate(raceDate),
				dob: dateValue,
				gender: gender || 'Male',
				currentWeeklyMileage: runningActivity,
				unit: metricArial,
				lastLongestRun: longestRecentRun,
				raceType: raceDetails?.selectedRaceDistance,
				raceDistance: getMarathonMiles(raceDetails?.selectedRaceDistance)
			})

			handleIsStepComplete(runningActivity && longestRecentRun ? true : false, stageNumber)
		}

	}, [dateValue, runningActivity, longestRecentRun, subStep])

	useEffect(() => {
		const stageNumber = '1-1'
		if (`1-${subStep}` === stageNumber) {
			addDetails(stageNumber, {
				preferredLongRun: longRunDay,
				runDaysPerWeek: daysPerWeek
			})
			handleIsStepComplete(longRunDay && daysPerWeek ? true : false, stageNumber)
		}
	}, [longRunDay, daysPerWeek, subStep])

	useEffect(() => {
		const stageNumber = '1-2'
		if (`1-${subStep}` === stageNumber) {
			addDetails(stageNumber, {
				availableRunDays: availableDays
			})
			handleIsStepComplete(availableDays?.length >= daysPerWeek ? true : false, stageNumber)
		}

	}, [availableDays, subStep])

	useEffect(() => {
		if (allStageDetails?.['1-2']?.availableRunDays != availableDays) {
			setUpdatedBaseDetails(true)
			// console.log('updated some details in EstablishBasePlan')
		}
	}, [dateValue, runningActivity, longestRecentRun, longRunDay, daysPerWeek, availableDays])

	useEffect(() => {
		!initialRef.current && setAvailableDays(daysPerWeek === daysOfWeek.length ? daysOfWeek : [longRunDay])
		initialRef.current = false
	}, [daysPerWeek, longRunDay])


	const renderBasePlan = () => {
		return <Grid container>
			{/* <Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				Date of birth
			</Grid>
			<Grid container sx={{ maxWidth: '150px' }}>
				<RSDateInput date={dateValue} setDate={setDateValue} maxDate={moment()} outlineSX={{ border: '1px solid #9FCD2B' }} />
			</Grid> */}
			<Grid container>
				<Grid container xs={12} sx={{ pb: 1 }} alignItems='flex-end'>
					<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1.3em', marginBottom: '0px' }}>
						{moment(thisSunday()).format('MMM D, YYYY') != moment(startDate).format('MMM D, YYYY') ?
							<>What do you anticipate averaging per week when the plan starts on <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>{moment(startDate).format('MMM D, YYYY')}</span>?</>
							:
							<>In the past <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>four weeks</span>, what’s your weekly average per week?</>
						}
					</p>
				</Grid>
				<Grid container>
					<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={runningActivity} setValue={setRunningActivity} wholeNumber min={0} max={70} label={metricArial} placeholder={runningAct && runningAct !== 'No' ? runningAct : '0'} />
				</Grid>
				<Grid container xs={12} sx={{ pt: 2, pb: 1 }} alignItems='flex-end'>
					<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1.3em', marginBottom: '0px' }}>
						{moment(thisSunday()).format('MMM D, YYYY') != moment(startDate).format('MMM D, YYYY') ?
							<>What do you anticipate your longest run to be around this time?</>
							:
							<>What is your longest run in the last <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>two weeks</span>?</>}
					</p>
				</Grid>
				<Grid container>
					<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={longestRecentRun} setValue={setLongestRecentRun} wholeNumber min={0} max={22} label={metricArial} placeholder={"6"} />
				</Grid>
			</Grid>
		</Grid >
	}

	const renderPreferredDays = () => {
		return <Grid container>
			<Grid container xs={12} alignItems='flex-end'>
				<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em', marginBottom: '0px' }}>
					How many  {<span style={{ fontFamily: 'Poppins-Bold' }}>days per week</span>} do you prefer to run?
				</p>
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.3em', pt: '2px', fontStyle: 'italic' }} alignItems='flex-end'>
				Minimum 3 days
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{[...Array(5)].map((_, d: any) => {
					const day = d + 3
					const isActive = daysPerWeek === day
					return <Box key={d + '-dpw'} sx={{ p: '0px 1px', height: '50px', width: '45px', mr: 1 }}>
						<RSButton card style2 customized secondary={isActive} onClick={() => setDaysPerWeek(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%' }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px', color: '#010101' }}>{day}</Box>
						</RSButton>
					</Box>
				})}
			</Grid>
			<Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px !important', lineHeight: '1em' }} alignItems='flex-end'>
				What day is best for your long run?
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{daysOfWeek?.map((day: string, index: number) => {
					const slicedDay = daysOfWeekABBV[index]
					const isActive = longRunDay === day
					return <Grid container key={index + day + 'lr'} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
						<RSButton card style2 customized secondary={isActive} onClick={() => setLongRunDay(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%', color: '#010101', px: 0 }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px !important', color: '#010101' }}>{slicedDay}</Box>
						</RSButton>
					</Grid>
				})}
			</Grid>
		</Grid>
	}

	const renderAvailableDays = () => {
		return <Grid container>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				{`Select all days you're able to run`}
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.3em', pt: '2px', fontStyle: 'italic' }} alignItems='flex-end'>
				Minimum {daysPerWeek} days
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{daysOfWeek?.map((day: string, index: number) => {
					const slicedDay = daysOfWeekABBV[index]
					const isActive = availableDays?.includes(day)
					const isDayLongRun = longRunDay === day || daysPerWeek === daysOfWeek.length
					return <Grid container key={index + day} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
						<RSButton card style2 customized secondary={isActive} disabled={isDayLongRun} onClick={() => !isDayLongRun && (isActive ? setAvailableDays(availableDays.filter((ad: string) => ad !== day)) : setAvailableDays([...availableDays, day]))}
							sx={{ textTransform: 'none !important', opacity: isDayLongRun ? 0.5 : 1, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%', px: 0 }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px !important', color: '#010101' }}>{slicedDay}</Box>
						</RSButton>
					</Grid>
				})}
			</Grid>
		</Grid>
	}

	const stepData = [
		{ body: renderBasePlan() },
		{ body: renderPreferredDays() },
		{ body: renderAvailableDays() }
	]

	const { tabsUI, handleChangeActive } = useRSTabs({ data: stepData, animateDesktop: true })

	useEffect(() => {
		handleChangeActive(subStep)
	}, [subStep])

	return <Box sx={{ px: 3 }}>
		<Grid container xs={12} sx={{ pb: '10px', fontFamily: 'Poppins-Medium', fontSize: '20px', lineHeight: '1em' }} alignItems='flex-end'>
			Tell us about your running
		</Grid>
		<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em', pb: 3 }} alignItems='flex-end'>
			We’ll use this information to personalize your plan to meet you where you are currently.
		</Grid>
		{tabsUI}
	</Box>
}

export default EstablishBasePlan;
