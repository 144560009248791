import { FC } from 'react'

export type UmbrellaBeachProps = {
	height?: number | string,
	width?: number | string,
}

const UmbrellaBeach: FC<UmbrellaBeachProps> = ({height = '100%', width = '100&'}) => {
	const isHNum = typeof height === 'number' ? 'px' : ''
	const isWNum = typeof width === 'number' ? 'px' : ''
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height={`${height}${isHNum}`} width={`${width}${isWNum}`}>
			<path d="M346.3 271.8l-60.1-21.9L214 448 32 448 0 448l0 64 32 0 512 0 32 0 0-64-32 0-261.9 0 64.1-176.2zm121.1-.2l-3.3 9.1 101.7 37c20.7-90.4-9.7-183.3-75.2-244.3c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM88.4 143.9l105 38.2 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2C217 7.7 131.5 59.7 88.4 143.9z"/>
		</svg>
	)
}

export default UmbrellaBeach
