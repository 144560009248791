import { postReq } from "../modules/apiConsume"
import useAIPlanPrompts from "../modules/useAIPlanPrompts"
import CryptoJS from 'crypto-js';

const useRSRunAIsuggestions = () => {

    const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

    const { defaultSytemPrompt, buildPromptSuggestedWeeklyMileage, buildPromptSuggestedGoalTime, buildUserRecommendation } = useAIPlanPrompts()

    const defaultPromptCall = async (prompt: any) => {

        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]

        // console.log('getting suggestions...')
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o', gptTemp: '0' })
        if (response && response.status === "error") {
            alert(response.error.error.error.message)
        }

        return response?.data?.output || []
    }

    const getSuggestedWeeklyMileage = async (stageData: any) => {

        let latestResults: any = []
        // const weeklyRunData = stageData["1-0"] || {}
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        const prompt = buildPromptSuggestedWeeklyMileage(mergedStageDetails)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)

        latestResults = { average: response?.average || '19', peak: response?.peak || '25' }
        // console.log('>>> Suggested weekly average: ', latestResults)

        return latestResults
    }

    const getSuggestedGoalTime = async (userRunData: any) => {

        let latestResults: any = []

        const prompt = buildPromptSuggestedGoalTime(userRunData)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.goalFinishTime || { seconds: '0', minutes: '0', hours: '0' }

        return latestResults
    }

    const checkPlanEligibility = async (userDetails: any) => {

        const mergedStageDetails = { ...userDetails["1-0"], ...userDetails["1-1"], ...userDetails["1-2"], ...userDetails["2"], ...userDetails["3"] }
        const prompt = buildUserRecommendation(mergedStageDetails)
        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();

        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o', gptTemp: '0' })
        if (response && response.status === "error") {
            alert(response.error.error.error.message)
        }
        const latestResults = response?.data?.output || []
        // console.log('>>> AI plan eligibility: ', latestResults)

        return latestResults
    }

    return { getSuggestedWeeklyMileage, getSuggestedGoalTime, checkPlanEligibility }
}

export default useRSRunAIsuggestions