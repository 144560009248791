import axios from 'axios'
import { IS_PROD, API_PORT, WEB_PORT, CSS_DEV_MODE, LOCAL_MACHINE } from './cliEnvValues'

// Constants

const host = IS_PROD && LOCAL_MACHINE ? 'https://192.168.2.27'
	: !IS_PROD && LOCAL_MACHINE ? 'http://192.168.2.27'
		: IS_PROD && !LOCAL_MACHINE ? 'https://dev.runsmartonline.com'
			: 'http://dev.runsmartonline.com'
/**
 * API Url
 */

export const apiUrl = IS_PROD && !LOCAL_MACHINE ? `${host}/api` : `${host}:${API_PORT}/api`

/**
 * Web Urls
 */

export const webUrl = IS_PROD && !LOCAL_MACHINE ? host : `${host}:${WEB_PORT}`

// Axios Method
type AxiosMethod = (endpoint: string, data?: any, config?: any) => any

/**
 * Request
 */

async function request(
	endpoint: string,
	func: AxiosMethod,
	ctx?: any,
	data?: any,
	args?: any
) {

	axios.defaults.params = { k: new Date().getTime() }
	try {
		const cookieHeader = ctx?.req?.headers?.cookie
			? { cookie: ctx.req.headers.cookie }
			: undefined
		const noCacheHeaders = {
			Pragma: 'no-cache',
			'Cache-Control': 'no-cache',
			Expires: '0',
		}
		const config = {
			withCredentials: true,
			headers: { ...cookieHeader, ...noCacheHeaders },
			signal: args?.signal
		}
		const res = await func(
			endpoint,
			data ? data : config,
			data ? config : undefined,
		)
		return res?.data
	} catch (err: any) {
		return err ? err?.response?.data : {}
	}
}

/**
 * Request Wrapper
 */

async function requestWrapper(
	endpoint: string,
	func: AxiosMethod,
	args?: any,
	ctx?: any,
	data?: any
) {
	return await request(urlString(endpoint), func.bind(axios), ctx, data, args)
}

/**
 * Get URL Parms
 */

export function getUrlParms(args?: any) {
	let queryString = ''
	if (args != null) {
		for (const key in args) {
			const val = args[key]
			if (typeof val == 'object' || Array.isArray(val)) {
				args[key] = JSON.stringify(val)
			}
			queryString = new URLSearchParams(args).toString()
		}
	}
	return queryString
}

/**
 * Get URL Encoded String
 */

export function urlString(endpoint: string, args?: any, useWebUrl = false) {
	const externalUrl = endpoint.includes('://')
	const queryString = getUrlParms(args)
	if (endpoint[0] == '/') endpoint = endpoint.substring(1)
	const pageWithQuery =
		queryString.length > 0 ? `${endpoint}?${queryString}` : endpoint
	if (externalUrl) return pageWithQuery
	else {
		const baseUrl = useWebUrl ? webUrl : apiUrl
		return `${baseUrl}/${pageWithQuery}`
	}
}

/**
 * Get Request
 */

export const getReq = async (endpoint: string, args?: any, ctx?: any) =>
	await requestWrapper(endpoint, axios.get, args, ctx)

/**
 * Post Request
 */

export const postReq = async (endpoint: string, data?: any, ctx?: any) =>
	CSS_DEV_MODE && !IS_PROD
		? {}
		: await requestWrapper(endpoint, axios.post, undefined, ctx, data)

/**
 * Put Request
 */

export const putReq = async (endpoint: string, data?: any, ctx?: any) =>
	CSS_DEV_MODE && !IS_PROD
		? {}
		: await requestWrapper(endpoint, axios.put, undefined, ctx, data)

/**
 * Patch Request
 */

export const patchReq = async (endpoint: string, data?: any, ctx?: any) =>
	CSS_DEV_MODE && !IS_PROD
		? {}
		: await requestWrapper(endpoint, axios.patch, undefined, ctx, data)

/**
 * Delete Request
 */

export const deleteReq = async (endpoint: string, data?: any, ctx?: any) =>
	CSS_DEV_MODE && !IS_PROD
		? {}
		: await requestWrapper(endpoint, axios.delete, undefined, ctx, data)

/**
 * Nav Request
 */

export function navReq(endpoint: string, args?: any) {
	return (window.location.href = urlString(endpoint, args, true))
}

/**
 * New Tab Request
 */

export const newTab = (endpoint: string, args?: any) => {
	return (window as any).open(urlString(endpoint, args), '_blank').focus()
}

/**
 * Back Navigation
 */

export const backNav = () => window.history.back()

/**
 * Popup
 */

export const popUp = (endpoint: string, name: string, args?: any) => {
	if (window) {
		window.open(
			urlString(endpoint, args),
			name,
			'height=750, width=1024, top=10, resizable=yes, scrollbars=yes, ' +
			'toolbar=no, menubar=yes, location=no, directories=no, status=no'
		)
	}
}

// Axios Method
type AxiosMethodHome = (endpoint: string, data?: any, config?: any) => any

/**
 * Request
 */

async function requestHome(
	endpoint: string,
	func: AxiosMethodHome,
	ctx?: any,
	data?: any
) {
	try {
		const cookieHeader = ctx?.req?.headers?.cookie
			? { cookie: ctx.req.headers.cookie }
			: undefined
		const noCacheHeaders = {
			Pragma: 'no-cache',
			'Cache-Control': 'no-cache',
			Expires: '0',
		}
		const config = {
			withCredentials: true,
			headers: { ...cookieHeader, ...noCacheHeaders },
		}
		const res = await func(
			endpoint,
			data ? data : config,
			data ? config : undefined
		)
		return res?.data
	} catch (err: any) {
		return err ? err?.response?.data : {}
	}
}

/**
 * Request Wrapper
 */

async function requestWrapperHome(
	endpoint: string,
	func: AxiosMethodHome,
	args?: any,
	ctx?: any,
	data?: any
) {
	return await requestHome(urlString(endpoint, args), func.bind(axios), ctx, data)
}

export const getReqHome = async (endpoint: string, args?: any, ctx?: any) =>
	await requestWrapperHome(endpoint, axios.get, args, ctx)