import { FC } from 'react'
import Box from '@mui/material/Box';

import RSButton from './RSButton';

export type RSChurrosButtonProps = {
    symbol: string | undefined,
    title: string,
    subtitle?: any,
    distance?: any,
    children?: any
    onClick?: Function,
    disabled?: boolean,
    className?: string,
    sx?: object,
    symbolSx?: object,
    dipColor?: string
}


const RSChurrosButton: FC<RSChurrosButtonProps> = ({ symbol, children, title, subtitle, distance, onClick, className, dipColor, sx = {}, symbolSx={}, disabled }) => {


    const handleClick = (e: any) => {
        onClick?.(e)
    }

    return <RSButton customized secondary card disabled={disabled} className={className ? className : ''} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', ...sx }}
        onClick={handleClick}>

        <div className='card-slanted' style={{ display: 'flex', width: '45%', height: '100%', background: disabled ? 'rgba(85,85,85,0.6)' : (dipColor || "black"), justifyContent: 'center', alignItems: 'center' }} >
            {symbol && (symbol.includes('fa') ? <i className={symbol} style={{ color: "white", fontSize: "35px", marginLeft: "-14px", ...symbolSx }} /> : <img src={symbol} style={{ scale: '1.1', marginRight: '14px', ...symbolSx }} />)}
            {/* <img src={symbol} style={{ scale: '1.1', marginRight: '14px' }} /> */}
        </div>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '12px' }}>
            <Box sx={{ fontSize: `16px !important`, fontFamily: 'Poppins-Medium !important', lineHeight: '1.2em', textAlign: 'left' }}>{title}</Box>
            <Box sx={{ fontSize: '13px !important', fontFamily: 'Poppins-Light !important', lineHeight: '1.2em', textAlign: 'left' }}>{subtitle}</Box>
            <Box sx={{ fontSize: '13px !important', fontFamily: 'Poppins-Light !important', lineHeight: '1.2em', textAlign: 'left' }}>{distance}</Box>
            {children}
        </Box>
    </RSButton>
}

export default RSChurrosButton;
