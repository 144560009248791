import { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import levelSelection from '../training-plan/build/LevelSeletion.json'
import RSCircledSymbol from '../../components/buttons/RSCircledSymbol'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type ChooseAPlanTypeProps = {
	accountProfile: AccountProfile,
	allStageDetails: any,
	addDetails: any,
	handleIsStepComplete?: any
	setUpdatedBaseDetails: any
}

const ChooseAPlanType: FC<ChooseAPlanTypeProps> = ({ allStageDetails, addDetails, handleIsStepComplete, setUpdatedBaseDetails }) => {
	const stageNumber = '0'
	const { level: lvl } = allStageDetails?.[stageNumber] || {}
	const [level, setLevel] = useState<number | undefined>(lvl || undefined);

	useEffect(() => {
		addDetails(stageNumber, {
			level: level,
			stepResults: [],
			currentStep: 0
		})
		handleIsStepComplete(level ? true : false, stageNumber)

		allStageDetails?.['0']?.level != level && setUpdatedBaseDetails(true)

	}, [level])

	return <Box sx={{ px: 3 }}>
		<Grid container xs={12} justifyContent='center' alignItems='center'>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Bold', fontSize: '16px' }} justifyContent='center' alignItems='center'>
				You can adjust after your plan is built.
			</Grid>
			<Grid container xs={12} sx={{ flexDirection: 'column', pt: 2 }} justifyContent='center' alignItems='center'>
				{levelSelection.map((lvl: any, lvlI: number) =>
					<Grid container className='race-selection-container' key={lvlI + 'build-plan-lvl'} xs={12} sx={{ fontFamily: 'Poppins-Light !important', fontSize: '18px !important', mb: '10px' }} justifyContent="flex-start" alignItems="center">
						<RSCircledSymbol
							symbol={lvl.id}
							title={lvl.title_2}
							subtitle={lvl.description}
							onClick={() => setLevel(lvl.id)}
							secondary={level === lvl.id}
							sx={{ p: '15px 20px !important' }}
							styleTwo
						/>
					</Grid>
				)}
			</Grid>
		</Grid>
	</Box>
}

export default ChooseAPlanType;
